import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
var FileDownload = require('js-file-download');

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import './task.css'
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from "@mui/material/MenuItem";
import {
    Card, CardActions, CardContent, Chip,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Divider, ListItemIcon, Menu, Snackbar, ThemeProvider
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import axios from "axios";
import Typography from "@mui/material/Typography";
import {Col, Row} from "react-bootstrap";

function Task(props) {


    const { group,curCourse,email } = useContext(AppContext);
    const [Error,SetError] = useState(false)
    const [Attachment,SetAttachment] = useState(false)
    const [AttachmentName,SetAttachmentName] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [CurCourse,SetCurCourse] = curCourse
    const open = Boolean(anchorEl);
    const [Group,SetGroup] = group
    const [Email,SetEmail] = group
    const [openDelete,SetOpenDelete] = useState(false)
    const [openSubmit,SetOpenSubmit] = useState(false)
    const [Uploading, SetUploading] = useState(false)
    function deleteSubmission(e){
        e.preventDefault()
        axios.delete('/submit_task',{data:{task:props.task.id,individual:props.task.individual}})
            .then(response=>{
                SetAttachment(false)
                SetAttachmentName(false)
                props.task.complete = false
                props.task.waiting = true
                props.task.submitted = false
                SetOpenDelete(false)
                props.task.attachment = null
                props.task.submissiontime = null
            })

    }

    useEffect(()=>{
        SetError(false)
    },[Attachment])

    useEffect(()=>{
        if(props.task.attachment){
            SetAttachmentName(props.task.attachment)
        }
    },[props.task])
    function submitHomework(e){
        e.preventDefault()
        var task_id = props.task.id
        var input = document.getElementById('hw_upload_'+task_id);
        // SetInpuLength(input.files.length)
        // var file = []
        // if(input.files[0] !== undefined || input.files[0] !== null) {
        //     file = input.files[0]
        // }
        // console.log('file',file)
        SetUploading(true)
        let fd = new FormData();
        if(!Attachment){
            SetError('An error occurred')
        }else {


            fd.set('file', Attachment)
            fd.set('task', task_id)
            fd.set('individual', props.task.individual)
            axios({
                method: "post",
                url: "submit_task",
                data: fd,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(response => {
                    props.task.complete = true
                    SetUploading(false)
                    props.task.waiting = false
                    props.task.submitted = true
                    SetOpenSubmit(false)
                    SetError("Submitted")
                })
                .catch(error => {
                        SetUploading(false)
                        SetError('An error occurred saving your submission')
                    }
                )
        }
    }
    function handleCloseSnack(){
        SetError(false)
    }
    function handleCloseModal(){
        SetOpenDelete(false)
        SetOpenSubmit(false)
    }


    function downloadEval(e,filename){
        if(Group){
            axios.get('/download_eval',{params:{taskid:props.task.id,groupid:Group.id,studentid:Email},responseType: 'blob'})
                .then(response => {
                    // Creating the blob file and its url
                    var blob = new Blob([response.data]);
                    let url = window.URL.createObjectURL(blob);


                    // Creating the hyperlink and auto click it to start the download
                    let link = document.createElement('a');
                    link.href = url;
                    link.download = filename
                    link.click();
                    //window.location.href = response.url;
                });
        }

    }
    function downloadUpload(){
        if(Attachment){
            FileDownload(Attachment,AttachmentName);
        }
        else if(AttachmentName){
            axios.get('/download_file',{params:{taskid:props.task.id,courseid:CurCourse.id},responseType: 'blob'})
                .then(response => {
                    // Creating the blob file and its url
                    var blob = new Blob([response.data]);
                    let url = window.URL.createObjectURL(blob);


                    // Creating the hyperlink and auto click it to start the download
                    let link = document.createElement('a');
                    link.href = url;
                    link.download = AttachmentName
                    link.click();
                    //window.location.href = response.url;
                });
                // .then(response=>{
                //
                //     FileDownload((response.data), AttachmentName);
                //
                //
                // })
        }

    }
    return (
        <div style={{margin:'2%'}}>
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <Dialog
                open={openDelete || openSubmit}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {openDelete && "Delete the current submission?"}
                    {openSubmit && "Upload submission?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {Uploading === false ? <>You can always upload a new file until the deadline.</> : <>
                        Uploading. Please wait
                    </>}
                    </DialogContentText>
                </DialogContent>
                {!Uploading && <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    {openDelete && <Button onClick={deleteSubmission} autoFocus>
                        Ok
                    </Button>}
                    {openSubmit && <Button onClick={submitHomework} autoFocus>
                        Ok
                    </Button>}
                </DialogActions>}
            </Dialog>
        <Card variant={'outlined'} sx={{padding:'2%'}}>
            <CardContent>
                <Typography component="div"><>
                    {props.task.mandatory && props.task.computational === false  && <><Chip variant={'outlined'}  label="Mandatory" />&nbsp;</>}
                    {props.task.optional && props.task.computational === false  && <><Chip variant={'outlined'}  label="Optional" />&nbsp;</>}
                    {props.task.individual   && <><Chip variant={'outlined'} label="Individual" />&nbsp;</>}
                    {props.task.group  && <><Chip  variant={'outlined'}  label="Group" />&nbsp;</>}
                    {props.task.assessment   && <><Chip variant={'outlined'} label="Assessment" />&nbsp;</>}
                    {props.task.computational && <><Chip variant={'outlined'} label="Computational" />&nbsp;</>}
                    {props.task.open && props.task.computational === false && <><Chip color={'primary'} label="Open" />&nbsp;</>}
                    {props.task.closed && props.task.computational === false  && <><Chip color={'error'} label="Closed" />&nbsp;</>}
                    {props.task.complete=== true && props.task.computational === false  && <><Chip color={'success'} label="Complete" />&nbsp;</>}
                    {props.task.waiting === true && props.task.computational === false && props.task.attach_file  && <><Chip color={'warning'} label="Waiting for submission" />&nbsp;</>}
                    {props.task.submitted === true && props.task.computational === false && <><Chip color={'primary'} label="Submitted" />&nbsp;</>}
                    {props.task.not_submitted === true && props.task.computational === false && props.task.assessment === false && <><Chip color={'error'} label="Not Submitted" />&nbsp;</>}
                    <hr/>
                </>

                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Task is open from: </b><span>{props.task.taskfromdeadline}</span>&nbsp; <b> to: </b>&nbsp; <span>{props.task.taskdeadline}</span>
                </Typography>
                {/*<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>*/}
                {/*    <b>Deadline: </b><span>{props.task.taskdeadline}</span>*/}
                {/*</Typography>*/}
                <Typography variant="h5" component="div">
                    {props.task.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Range: {props.task.scorerange}
                </Typography>
                <Typography variant="body2">
                    {props.task.description}
                    <br/><br/>
                </Typography>
                {props.task.computational === false &&  props.task.attach_file && <Typography sx={{textAlign: 'left'}}>
                    {AttachmentName && <div>
                        <b>Date of submission: </b><>{props.task.submissiontime}</>


                    </div>}
                    <div><b>Attachment: </b>{AttachmentName ?
                        <Button onClick={downloadUpload}>{AttachmentName}</Button> :

                        <Button component="label"
                                disabled={!props.task.open || props.task.evaluation || !props.task.allowsubmission || (props.task.group && !Group)}
                                variant={'outlined'} size={'small'} color={'primary'}>
                            {/*<VisuallyHiddenInput type="file" />*/}
                            <input
                                id={'hw_upload_' + props.task.id}
                                type="file"
                                onChange={() => {
                                    var input = document.getElementById('hw_upload_' + props.task.id);
                                    // SetInpuLength(input.files.length)
                                    var files = []
                                    if (input.files[0] !== undefined || input.files[0] !== null) {
                                        for (let ind = 0; ind < input.files.length; ind++) {
                                            files.push(input.files[ind])
                                        }
                                    }


                                    SetAttachment(files[0])
                                    SetAttachmentName(files[0].name)

                                }}
                                hidden
                            />
                            Upload
                        </Button>}</div>
                    <hr/>
                </Typography>}
                {props.task.evaluation && <>
                    <Typography sx={{textAlign:'left'}} variant="h6" component="div">
                        Evaluation
                    </Typography>
                    <Typography sx={{textAlign:'left'}} >
                        <Row>
                            <Col md={3}><b>Evaluation Date</b></Col>
                            <Col md={9}>{props.task.evaluation.time}</Col>
                        </Row>
                        <Row>
                            <Col md={3}><b>Score</b></Col>
                            <Col md={9}>{props.task.evaluation.score}</Col>
                        </Row>

                        {props.task.evaluation.comment && <Row>
                            <Col md={3}><b>Comment</b></Col>
                            <Col md={9}>
                                <div style={{whiteSpace: 'pre-wrap'}}>{props.task.evaluation.comment}</div>
                            </Col>
                        </Row>}

                        {props.task.evaluation.attachment && <Row>
                            <Col md={3}><b>Attachment</b></Col>
                            <Col md={9}><Button onClick={(e)=>downloadEval(e,props.task.evaluation.attachment)}>{props.task.evaluation.attachment}</Button></Col>
                        </Row>}
                    </Typography>
                </>}

            </CardContent>
            {props.task.open === true && props.task.attach_file && props.task.computational === false && <div style={{textAlign: 'end'}}>
                <Button variant={'contained'} sx={{margin: '1%'}}
                         disabled={props.task.allowsubmission === false || !AttachmentName} onClick={() => {
                    SetOpenDelete(false)
                    SetOpenSubmit(true)
                }}>Save</Button>
                <Button variant={'contained'} sx={{margin:'1%'}} color={'error'} disabled={props.task.allowsubmission === false || !AttachmentName} onClick={()=>{
                    SetOpenDelete(true)
                    SetOpenSubmit(false)
                }}>Delete</Button>
            </div>}
        </Card>
        </div>

    );
}


export default Task;
