import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'
import './login.css'
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect} from "react-router-dom";
import {Paper} from "@mui/material";


function Login() {


    const { email,usertype } = useContext(AppContext);


    const [Email,SetEmail] = email
    const [UserType,SetUserType] = usertype
    const [UpdateSess,SetUpdateSess] = useState(false)
    const [Password,SetPassword] = useState('')
    const [Error,SetError] = useState('')
    const [Redir,SetRedir] = useState(false)

    console.log('login')


    useEffect(()=>{
        axios.get('/get_session').then(response=>{
            if(response.data['email'] !== "None" && response.data['email']){
                SetEmail(response.data['email'])
                SetUserType(response.data['user_type'])
                SetRedir(true)
            }

        })
    },[UpdateSess])


    const handleSubmit = (event) =>{
        event.preventDefault();
        event.stopPropagation();
        const data = new FormData(event.currentTarget);
        // console.log({
        //     username: data.get('username'),
        //     password: data.get('password'),
        // });
        var username = data.get('email').toLowerCase()
        if (data.get('email','').toLowerCase() !== '' && data.get('password','') !== '' ){
            axios({
                method: "post",
                url: "login",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((response) =>{
                    //handle success
                    console.log('resp',response)
                    if(response.data['error']){
                        SetError('The password or the username are not valid')

                    }else{
                        SetUpdateSess(true)
                        // SetEmail(username)
                        // SetRedir(true)
                    }

                    // }

                })
                .catch((error) =>{
                    //handle error
                    console.log(error);
                    SetError('The password or the username are not valid')
                    SetRedir(0)
                    SetEmail('')
                    SetPassword('')
                });
        }
        else {
            SetError('The password or the username are missing')
        }

    }









    return (
        <div className="App">




            <div >
                <Container fluid>
                    {UserType && Redir && <Redirect to='/my-courses'/>}
                <div>
                    {/*<Paper>*/}
                    <div className={'reglog'}>
                        <div><img className={'login'} src={"http://crane.dei.unipd.it/static/img/cutlogostart.png"} />
                        </div>
                        <div style={{textAlign:'center'}}><h4>Login</h4>
                        </div>
                        {Error !== '' && <div style={{width:'30vw',display:"inline-block"}}><Alert severity="error">{Error}</Alert></div>}
                        <Box component="form" onSubmit={(e)=>{handleSubmit(e)}} noValidate sx={{ mt: 1 }}>
                            <FormControl>
                                <div style={{marginTop:'3vh'}}>
                                    <TextField sx={{ width:'350px'}}
                                               name="email" required id="standard-basic" label="Email" variant="outlined" />
                                </div>
                                <div style={{marginTop:'3vh'}}>
                                    <div style={{fontSize:'0.65rem',margin:0,textAlign:'right'}}><a href={window.location.origin+'/password_reset'}>I forgot my password</a> </div>

                                    <TextField
                                        required
                                        sx={{ width:'350px' }}
                                        id="standard-password-input"
                                        label="Password"
                                        type="password"
                                        name="password"
                                        autoComplete="current-password"
                                        variant="outlined"
                                    />
                                </div>
                                <Button type="submit"  sx={{ '& > :not(style)': { m: 1 },background:"linear-gradient(90deg, rgb(90, 8, 8) 0%, rgb(90, 8, 8) 100%)" }} size={'large'}
                                        variant="contained" style={{marginTop:'5vh',width:'350px'}}>Log In</Button>
                            </FormControl>


                        </Box>

                        <br/>
                        <div style={{marginTop:'2vh'}}>
                            <Link href={window.location.origin+"/register"} variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </div>

                    </div>
                    {/*</Paper>*/}
                </div>
                </Container>
            </div>

        </div>
    );
}


export default Login;
