import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import './hp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {Container,Row,Col} from "react-bootstrap";
import ButtonMaterial from "../utils/ButtonUnipd";

function HomePage() {


    const { academicYears,group,email,studentCourses,courseslist,studentDegree,degrees } = useContext(AppContext);

    const [StudentCourses,SetStudentCourses] = studentCourses
    const [AcademicYears,SetAcademicYears] = academicYears
    const [CourseList,SetCourseList] = courseslist
    const [OpenSearch,SetOpenSearch] = useState(false)
    const [CourseToAdd,SetCourseToAdd] = useState(false)
    const [StudentDegree,SetStudentDegree] = studentDegree
    const [Degrees,SetDegrees] = degrees
    const [Email,SetEmail] = email







    return (
        <div className="App">




            <div >
                <Container fluid>



                    <div className={'image_back'}>

                        <div className={'header'}>
                            <div><img className={'login'} src={"http://crane.dei.unipd.it/static/img/cutlogostart.png"} />
                            </div>
                            {/*<ButtonMaterial size='large' href="login">Login</ButtonMaterial>*/}
                            {/*<ButtonMaterial size='large' href={window.location.origin + "/register"}>Sign Up</ButtonMaterial>*/}
                        </div>
                        <div className={'intro'}>
                            <h1>CRANE</h1>
                            <h4>Course pRoject mANagEment</h4>
                            <hr/>
                            <div className={'intropar'}>CRANE is a platform where students can join courses, create groups, and collaboratively work on their homeworks</div>
                            {/*<ButtonMaterial href="register">Learn More</ButtonMaterial>*/}
                            <div >
                                <ButtonMaterial  href="login">Login</ButtonMaterial>
                                <ButtonMaterial  href={window.location.origin + "/register"}>Sign Up</ButtonMaterial>
                            </div>

                        </div>
                    </div>
                    <div className={'rectangle'}>
                        Who is <b>CRANE</b> for?
                    </div>

                    <div className={'body-content'}>
                        <div>
                            <Row >
                                <Col md={3}> <div className={'textheading'}>STUDENTS
                                    {/*<ArrowRightIcon color={'#941010'} sx={{fontSize:40}} />*/}
                                </div></Col>
                                <Col md={9}><div className={'textintroleft textintro'}>
                                    <b>Students</b> can join courses, <b>track</b> projects and homeworks for each course. They can <b>form groups</b> and <b>submit</b> their work. Additionally, they can <b>assess</b> their colleagues' contribution to their joint work and <b>view</b> not only the grades and comments received on each homework but also the final score.
                                </div></Col>
                            </Row>
                        </div>
                        <hr/>
                        <div>
                            <Row >
                                <Col md={3}>
                                    <div className={'textheading'}>
                                        {/*<ArrowLeftIcon color={'#941010'} sx={{fontSize:40}} />*/}
                                        PROFESSORS</div>

                                </Col>
                                <Col md={9}>
                                <div className={'textintroleft textintro'}>
                                    <b>Professors</b> can <b>setup courses</b>, <b>track</b> both individual student and group activities, <b>organize</b> and <b>score</b> the assigned homeworks for the courses, and <b>publish</b> each student's final score, possibly calibrated by <b>students' peer evaluation</b>
                                </div></Col>
                            </Row>

                        </div>


                    </div>


                </Container>
            </div>

        </div>
    );
}


export default HomePage;


// <Card sx={{ maxWidth: 345 }}>
//     <CardMedia
//         sx={{ height: 140 }}
//         image="https://crane.dei.unipd.it/static/images/team-img.jpeg"
//         title="green iguana"
//     />
//     <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//             Professors
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//             Professors create course, tasks and keep track of student's homeworks
//         </Typography>
//     </CardContent>
//     <CardActions>
//         <Button size="small">Learn More</Button>
//     </CardActions>
// </Card>