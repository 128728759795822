import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import './course.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'
import '../../App.css';
// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect} from "react-router-dom";
import {
    Autocomplete,
    CircularProgress,
    Collapse, createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Snackbar, ThemeProvider
} from "@mui/material";
import SearchCourseBar from "./SearchCourseBar";
import HeaderBar from "../utils/headerbar";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {cloneDeep} from "lodash";
import dayjs from "dayjs";


function CoursesListProfessor() {


    const { academicYears,group,email,studentCourses,courseslist,studentDegree,degrees,curCourse } = useContext(AppContext);

    const [ProfessorCourses,SetProfessorCourses] = studentCourses
    const [AcademicYears,SetAcademicYears] = academicYears
    const [Email,SetEmail] = email
    const [CourseList,SetCourseList] = courseslist
    const [OpenCreate,SetOpenCreate] = useState(false)
    const [CourseToAdd,SetCourseToAdd] = useState(false)
    const [StudentDegree,SetStudentDegree] = studentDegree
    const [Degrees,SetDegrees] = degrees
    const [Group,SetGroup] = group
    const [OpenDialog,SetOpenDialog] = useState(false)
    const [CFU,SetCFU] = useState(false)
    const [Error,SetError] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [RemoveCourse,SetRemoveCourse] = useState(false)
    const [ModifyCourse,SetModifyCourse] = useState(false)
    const [Redir, SetRedir] = useState(false)
    const [CourseToRemove, SetCourseToRemove] = useState(false)
    const [CourseToMod, SetCourseToMod] = useState(false)

    // aux states
    const [Alias,SetAlias] = useState([])
    const [Credits,SetCredits] = useState([])
    const [EnrollDeadline,SetEnrollDeadline] = useState(false)
    const [GroupDeadline,SetGroupDeadline] = useState(false)

    useEffect(()=>{
        if(CourseToMod){
            SetAlias(CourseToMod.alias.map(a => a.name))
            SetCredits(CourseToMod.cfus)
            SetEnrollDeadline(CourseToAdd.enrolldeadline_txt)
            SetGroupDeadline(CourseToAdd.groupdeadline_txt)
        }else{
            SetAlias([])
            SetCredits([])
            SetEnrollDeadline(false)
            SetGroupDeadline(false)
        }
    },[CourseToMod])
    const styleObj = {
        '&:disabled': {
            color: 'darkgreen',     // Change the text color for disabled state
            border: '1px solid darkgreen',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };
    const styleObj1 = {
        '&:disabled': {
            color: 'darkred',     // Change the text color for disabled state
            border: '1px solid darkred',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };

    useEffect(()=>{
        if(CourseToAdd){
            if(CourseToAdd.cfus.length === 1){
                SetCFU(CourseToAdd.cfus[0])
            }else{
                SetCFU(false)
            }
        }
    },[CourseToAdd])


    const theme = createTheme({
        root: {

            '&:disabled': {
                background: 'gray', // Change the background color for disabled state
                color: 'darkgreen',     // Change the text color for disabled state
                border: 'darkgreen',     // Change the text color for disabled state
                boxShadow: 'none',  // Remove the box shadow for disabled state
            },
        },
    })

    useEffect(()=>{
        SetCurCourse(false)
        SetGroup(false)

        if(!ModifyCourse){
            axios.get("courses",{params:{type:'professor'}}).then(response=>{
                SetProfessorCourses(response.data['courses'])
            }).catch(error=>console.log(error))

            axios.get("courses").then(response=>{
                SetCourseList(response.data['courses'])
            }).catch(error=>console.log(error))

            axios.get("degree").then(response=>{
                SetDegrees(response.data['degrees'])
            }).catch(error=>console.log(error))

            axios.get("academic_years").then(response=>{
                SetAcademicYears(response.data['academicyears'])
            }).catch(error=>console.log(error))
        }



    },[ModifyCourse])

    function normalize(string){
        string = string.toLowerCase().replaceAll(' ', '').replaceAll('null', '')
        return (string !== '' && string !== null && string && string !== undefined)
    }



    const handleClose = () => {
        SetOpenDialog(false)


    };
    useEffect(()=>{
        SetError(false)
    },[StudentDegree,CourseToAdd])

    function modifyCourse(){
        if(CourseToMod){
            if(Credits.length > 0 && CourseToMod.name !== "" && CourseToMod.academic_year !== "" && GroupDeadline && EnrollDeadline){
                axios.post("/courses",{id:CourseToMod.id,name:CourseToMod.name,academic_year:CourseToMod.academic_year,groupdeadline:GroupDeadline,
                    enrolldeadline:EnrollDeadline,cfus:Credits,alias:Alias})
                    .then(response=>{
                        if(response.data['error']){
                            SetError(response.data['error'])
                        }else{
                            const updatedList = ProfessorCourses.filter(item => item.id !== CourseToMod.id);
                            SetProfessorCourses([...updatedList,CourseToMod])
                            SetModifyCourse(false) // handle course object
                            SetCourseToMod(false) // handle modal
                        }

                    }).catch(error=>{
                    SetError('an error occurred updating the course.')
                })
            }else{
                SetError('enroll deadline, group deadline, name and cfus must be non empty')
            }

        }
    }

    function removeCourse(){
        if(CourseToRemove){
            axios.delete("/courses",{data:{course:CourseToRemove.id}})
                .then(response=>{
                    if(response.data['error']){
                        SetError(response.data['error'])
                    }else{
                        const updatedList = ProfessorCourses.filter(item => item.id !== course.id);
                        SetProfessorCourses(updatedList)
                        SetCourseToRemove(false) // handle course object
                        SetRemoveCourse(false) // handle modal
                    }

                }).catch(error=>{
                SetError('an error occurred removing the course.')
            })
        }

    }

    function handleCloseRemove(){
        SetRemoveCourse(false)
        SetCourseToRemove(false)
    }
    function handleCloseModify(){
        SetCourseToMod(false)
        SetModifyCourse(false)
    }

    function handleCloseSnack(){
        SetError(false)
    }

    function removeCredits(e,credits){
        e.preventDefault()
        const updatedList = Credits.filter(item => item !== credits);
        SetCredits(updatedList)
        var s = document.getElementById('credits')
        s.value = ''
    }
    function removeAlias(e,alias){
        e.preventDefault()

        const updatedList = Alias.filter(item => item !== alias);
        SetAlias(updatedList)
        var s = document.getElementById('alias')
        s.value = ''

    }

    return (

        <div>











            <HeaderBar />



            <div>
                {(AcademicYears) ?
                    <div>
                        <div >
                            <Container fluid>

                                <div style={{textAlign:'center',margin:'2%'}}>
                                    <h1>My Courses</h1>

                                    <div className={'addcourse'}>
                                        <Button variant="outlined"
                                                href={window.location.origin+"/create_course"}

                                        >
                                            Create new course
                                        </Button>


                                    </div>

                                </div>

                        {ProfessorCourses.length === 0 && <div style={{margin:'0 3%'}}>
                            <h3>You have not created any course yet.</h3>
                        </div>}

                        {AcademicYears && ProfessorCourses && ProfessorCourses.length > 0 && AcademicYears.map(ay=>
                            <div className={'year'}>

                                <h2>{ay.name}</h2><hr/>
                                {ProfessorCourses.map(course=>
                                    <>
                                        {course.academic_year === ay.name && course.is_alias === false && <div className={'profcourse'}>
                                            <div>

                                                <div style={{display:'inline-block'}}><a href={window.location.origin+"/courses/"+course.id}>{course.name}</a></div>
                                                {/*<div style={{display:'inline-block',marginLeft:'2%'}}>*/}
                                                {/*    <Button  size={'small'} variant = 'contained' color='primary' onClick={()=>{SetCourseToMod(course);SetModifyCourse(prev=>!prev)}}>Modify</Button>*/}
                                                {/*</div>*/}
                                                {/*<div style={{display:'inline-block',marginLeft:'2%'}}>*/}
                                                {/*    <Button  size={'small'} variant = 'contained' color='error' onClick={()=>{SetCourseToRemove(course);SetRemoveCourse(prev=>!prev)}}>Remove</Button>*/}
                                                {/*</div>*/}
                                                {/*<div>CFU: <b>{course.cfus.join(', ')}</b></div>*/}
                                                {/*<div>Students can join the course until: {course.enrolldeadline_txt}</div>*/}
                                                {/*<div>Students can join and update groups until: {course.groupdeadline_txt}</div>*/}
                                                {/*<div>Enrolled students: <b>{course.enrolled}</b></div>*/}
                                                {/*<div>Withdrawn students: <b>{course.withdrawn}</b></div>*/}
                                                {/*<div>Groups: <b>{course.groups}</b></div>*/}
                                                {/*<div>Students with group: <b>{course.withgroup}</b></div>*/}
                                                {/*<div>Students without group: <b>{course.nogroup}</b> </div>*/}

                                            </div>
                                            {/*{course.alias.length > 0 && <div>*/}
                                            {/*    Alias:*/}
                                            {/*    <div>*/}
                                            {/*        <ul>*/}
                                            {/*            {course.alias.map(c=>*/}
                                            {/*                <li>*/}
                                            {/*                    {<>{c.name.split("(")}</>}*/}
                                            {/*                </li>*/}
                                            {/*            )}*/}
                                            {/*        </ul>*/}
                                            {/*    </div>*/}

                                            {/*</div>}*/}




                                        </div>}
                                    </>
                                )}
                            </div>
                        )}


                            </Container>
                        </div>


                    </div> : <div className={'circularbar'}><CircularProgress /></div>
                }
            </div>
        </div>

    );
}


export default CoursesListProfessor;
