import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import './course.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'
import '../../App.css';
// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect} from "react-router-dom";
import {
    Autocomplete,
    CircularProgress,
    Collapse, createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Snackbar, ThemeProvider
} from "@mui/material";
import SearchCourseBar from "./SearchCourseBar";
import HeaderBar from "../utils/headerbar";
import dayjs from "dayjs";


function CreateCourse(props) {

    const today = dayjs();
    const yesterday = dayjs().subtract(1, 'day');
    const todayStartOfTheDay = today.startOf('day');
    const defaultDeadline = dayjs().add(1, 'year').format('YYYY-MM-DD');


    const { academicYears,group,email,studentCourses,courseslist,studentDegree,degrees,curCourse } = useContext(AppContext);
    const [Loading, SetLoading] = useState(false);

    const [ProfessorCourses,SetProfessorCourses] = studentCourses
    const [AcademicYears,SetAcademicYears] = academicYears
    const [Email,SetEmail] = email
    const [CourseList,SetCourseList] = courseslist
    const [OpenCreate,SetOpenCreate] = useState(false)
    const [CourseToAdd,SetCourseToAdd] = useState(false)
    const [StudentDegree,SetStudentDegree] = studentDegree
    const [Degrees,SetDegrees] = degrees
    const [Group,SetGroup] = group
    const [AcademicYear,SetAcademicYear] = useState(false)
    const [Name,SetName] = useState(false)
    const [Acronym,SetAcronym] = useState(false)
    const [Credits,SetCredits] = useState([])
    const [Aliases,SetAliases] = useState([])
    const [EnrollDeadline,SetEnrollDeadline] = useState(defaultDeadline)
    const [GroupDeadline,SetGroupDeadline] = useState(defaultDeadline)
    const [OpenDialog,SetOpenDialog] = useState(false)
    const [CFU,SetCFU] = useState(false)
    const [Error,SetError] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [Redir,SetRedir] = useState(false)


    const styleObj = {
        '&:disabled': {
            color: 'darkgreen',     // Change the text color for disabled state
            border: '1px solid darkgreen',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };
    const styleObj1 = {
        '&:disabled': {
            color: 'darkred',     // Change the text color for disabled state
            border: '1px solid darkred',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };




    const theme = createTheme({
        root: {

            '&:disabled': {
                background: 'gray', // Change the background color for disabled state
                color: 'darkgreen',     // Change the text color for disabled state
                border: 'darkgreen',     // Change the text color for disabled state
                boxShadow: 'none',  // Remove the box shadow for disabled state
            },
        },
    })

    useEffect(()=>{
        SetCurCourse(false)
        SetGroup(false)

        axios.get("academic_years").then(response=>{
            SetAcademicYears(response.data['academicyears'])
        }).catch(error=>console.log(error))


    },[])

    function normalize(string){
        string = string.toLowerCase().replaceAll(' ', '').replaceAll('null', '')
        return (string !== '' && string !== null && string && string !== undefined)
    }



    const handleClose = () => {
        SetOpenDialog(false)


    };
    useEffect(()=>{
        console.log(GroupDeadline)
    },[GroupDeadline])

    useEffect(()=>{
        console.log(Name)
    },[Name])

    useEffect(()=>{
        SetError(false)
    },[StudentDegree,CourseToAdd])

    function submit(e){
        e.preventDefault()
        if(Name !== "" && Acronym !== "" && Acronym && Credits.length >0  && AcademicYear){
            SetLoading(true)
            var enrolldeadline = EnrollDeadline.format('YYYY-MM-DD').toString()
            var groupdeadline = GroupDeadline.format('YYYY-MM-DD').toString()
            axios.post("/courses",{name:Name,acronym:Acronym,credits:Credits,aliases:Aliases,groupdeadline:groupdeadline,enrolldeadline:enrolldeadline,academicyear: AcademicYear})
                .then(response=>{
                    SetLoading(false)
                    if(response.data['error']){
                        SetError(response.data['error'])
                        SetCredits([])
                        SetAlias([])
                    }else{
                        SetRedir(true)

                    }
                }).catch(error=> {
                SetError('An error occurred');
                SetLoading(false)
            })

        }else{
            SetError("courses information such as: name, cfus must be NON empty")
        }

    }

    function addAlias(e){
        e.preventDefault()
        var alias = document.getElementById('alias').value
        if(alias !== ""){
            document.getElementById('alias').value = ''
            if(Aliases.indexOf(alias) === -1){
                SetAliases([...Aliases,alias])

            }
        }

    }
    function addCredits(e){
        e.preventDefault()
        var credits = document.getElementById('credits').value
        document.getElementById('credits').value = ''
        if(credits !== "") {

            if (Credits.indexOf(credits) === -1) {
                SetCredits([...Credits, credits])

            }
        }
    }
    function removeCredits(e,credits){
        e.preventDefault()
        const updatedList = Credits.filter(item => item !== credits);
        SetCredits(updatedList)
    }
    function removeAlias(e,alias){
        e.preventDefault()

        const updatedList = Aliases.filter(item => item !== alias);
        SetAliases(updatedList)

    }
    function handleCloseSnack(){
        SetError(false)
    }

    const handleChange = (event) => {
        var ay = document.getElementById('ay')
        SetAcademicYear(event.target.value);
    };


    return (
        <div>
            <HeaderBar />
            {Redir === true && <Redirect to='/my-courses'/>}

            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />


            <div>
                {(AcademicYears && !Loading) ?
                    <div>
                        <div >
                            <Container fluid>

                                <div style={{textAlign:'center',margin:'2%'}}>
                                    <h1>Create course</h1>
                                    <Row>
                                        <Col md={2}></Col>

                                        <Col md={8}>
                                            <div style={{margin:'2%'}}>
                                                <TextField id="name" label="Name" sx={{width:'100%',margin:'2%'}}  variant="outlined" onChange={(e)=>{SetName(e.target.value)}}/>

                                            </div>
                                            <div style={{margin:'2%'}}>
                                                <TextField id="acro" label="Acronym" sx={{width:'100%',margin:'2%'}}  variant="outlined" onChange={(e)=>{SetAcronym(e.target.value)}}/>

                                            </div>
                                            <div style={{margin:'2%'}}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="ay"
                                                    label={'Academic Year'}
                                                    options={AcademicYears.map(ay=>({'id':ay.id,'label':ay.name}))}
                                                    sx={{ width: '100%',margin:'2%' }}
                                                    onChange={(event, newValue) => {
                                                        SetAcademicYear(newValue.id);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Academic year" />}
                                                />
                                                {/*<Select*/}
                                                {/*    labelId="demo-simple-select-label"*/}
                                                {/*    id="ay"*/}
                                                {/*    sx={{width: '100%'}}*/}
                                                {/*    label="Academic Year"*/}
                                                {/*    onChange={handleChange}*/}
                                                {/*>*/}
                                                {/*    {AcademicYears.map(ay=><MenuItem value={ay.id}>{ay.name}</MenuItem>)}*/}



                                                {/*</Select>*/}
                                            </div>
                                            <div style={{margin:'2%'}}>
                                                <div>
                                                    <TextField type='number' sx={{width:'100%',margin:'2%'}} id="credits" label="Credits" variant="outlined" />
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="contained" onClick={(e)=>addCredits(e)}>Add Credits</Button>
                                                </div>
                                                {Credits.length > 0 && <div style={{textAlign: 'left'}}>
                                                    <b>Credits:</b>

                                                    {Credits.map(c => <div>
                                                        <div style={{display: 'inline-block', margin: '1%'}}>{c}</div>
                                                        <div style={{display: 'inline-block', margin: '1%'}}><Button
                                                            variant="contained" size='small' color="error"
                                                            onClick={(e) => removeCredits(e, c)}>Remove</Button></div>


                                                    </div>)}</div>}
                                            </div>
                                            <div style={{margin:'2%'}}>
                                                <TextField id="alias" sx={{width:'100%',margin:'2%'}}  label="Aliases" variant="outlined" />
                                                <div>
                                                    <Button
                                                        variant="contained" onClick={(e)=>addAlias(e)}>Add Alias</Button>
                                                </div>
                                                {Aliases.length >0 && <div style={{textAlign: 'left'}}>
                                                    <b>Aliases:</b>
                                                    {Aliases.map(d => <div>
                                                        <div style={{display: 'inline-block', margin: '1%'}}>{d}</div>
                                                        <div style={{display: 'inline-block', margin: '1%'}}><Button
                                                            variant="contained" size='small' color="error"
                                                            onClick={(e) => removeAlias(e, d)}>Remove</Button></div>


                                                    </div>)}
                                                </div>}

                                            </div>
                                            <div style={{marginBottom:'2%'}}>
                                                Please, set enrollment deadline (the day until students can subscribe to the course) and group deadline (the day until students can create and modify groups). If you
                                                don't want to set deadline, they will be set as today's date one year from now.</div>
                                            <div style={{display:'flex',justifyContent:'center'}}>
                                                <div style={{margin:'2%',float:'left'}}>

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                                    disablePast label="Enrollment deadline" onChange={(newValue) => SetEnrollDeadline(newValue)} />
                                                    </LocalizationProvider>
                                                </div>
                                                <div style={{margin:'2%',float:'left'}}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DatePicker
                                                                     disablePast label="Group creation deadline" onChange={(newValue) => SetGroupDeadline(newValue)}/>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col md={2}></Col>
                                    </Row>
                                    <div style={{textAlign:"center"}}>

                                        <Button
                                            variant="contained" size = 'large' color="success" onClick={submit}>Create</Button>

                                    </div></div>




                            </Container>
                        </div>


                    </div> : <div className={'circularbar'}><CircularProgress /></div>
                }
            </div>
        </div>

    );
}


export default CreateCourse;
