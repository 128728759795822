import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
import Link from '@mui/material/Link';

import {

    Redirect
} from "react-router-dom";
import Alert from "@mui/material/Alert";
import './login.css'
//import img from "../../logo/img_1.png";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

function SignUp() {

    const { email,usertype } = useContext(AppContext);

    const [Redir,SetRedir] = useState(false)
    const [UserType,SetUserType] = usertype
    const [PasswordVal,SetPasswordVal] = useState('')
    const [PasswordValUp,SetPasswordValUp] = useState('')
    const [Name,SetName] = useState('')
    const [Surname,SetSurname] = useState('')
    const [Badge,SetBadge] = useState('')
    const [Email,SetEmail] = email
    const [Password,SetPassword] = useState('')
    const [Error,SetError] = useState('')
    const [PswCompliant,SetPswCompliant] = useState(true)
    const [EmailInvalid,SetEmailInvalid] = useState(false)
    const [PswEqual,SetPswEqual] = useState(true)
    const [ShowModalEmail,SetShowModalEmail] = useState(false)
    const [Confirmed,SetConfirmed] = useState(false)
    const [data,SetFormData] = useState(false)
    var validator = require("email-validator");



    function handleSubmit(event,confirmed=false){
        event.preventDefault();
        handleClose()
        var foormdata = null
        if(data === false){
            foormdata = new FormData(event.currentTarget);

            SetFormData(foormdata)
        }else{
            foormdata = data
        }
        console.log({
            email: foormdata.get('email'),
            name: foormdata.get('name'),
            surname: foormdata.get('surname'),
            badge: foormdata.get('badge'),
            password: foormdata.get('password'),
            password_check: foormdata.get('password_check'),

        });

        const regex_name = /[a-zA-Z]/;
        var name = foormdata.get('name','')
        var surname = foormdata.get('surname','')
        var surname_invalid = false;
        var name_invalid = false;
        if (!regex_name.test(name)){
            name_invalid = true
        }
        if (!regex_name.test(surname)){
            surname_invalid = true
        }
        const regex_badge = /[0-9]/;
        var badge = foormdata.get('badge','')
        var badge_invalid = false
        if (badge.length > 0 && !regex_badge.test(badge)){
            badge_invalid = true
        }
        var psw_invalid;
        var psw_check_invalid;
        var psw_equal = true;

        var email = foormdata.get('email','').toLowerCase()
        var email_invalid = !validator.validate(email);
        console.log('valid',email_invalid)// true
        SetEmailInvalid(email_invalid)

        if(PasswordVal !== PasswordValUp){
            SetPswEqual(false)
            psw_equal = true
        }
        var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

        var psw_comp = false
        var special = regex.test(PasswordVal);
        var len = PasswordVal.length >= 8
        var number = /[0-9]/.test(PasswordVal)
        var upper = /[A-Z]/.test(PasswordVal)
        if (!(special && upper && number && len)){

            SetPswCompliant(false)
        }
        else{
            psw_comp = true
            SetPswCompliant(true)
        }

        var psw_equal = false
        if(foormdata.get('password','') === foormdata.get('password_check','')){
            psw_equal = true
        }
        if (!Email.endsWith('unipd.it') && !confirmed){
            SetShowModalEmail(true)
        }

        else if(psw_comp && psw_equal && email_invalid === false && email.length > 0 && !name_invalid && !surname_invalid && !badge_invalid  && foormdata.get('password','') !== '' && foormdata.get('password_check','') !== '' ){
            var data_to_send = data ? data : foormdata
            axios({
                method: "post",
                url: "register",
                data: data_to_send,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    //handle success
                    if(response.data['error']){
                        SetError(response.data['error'])
                        SetRedir(false)
                        SetEmail(email)
                        SetUserType('student')


                    }else{
                        SetEmail(email)
                        SetUserType('student')

                        SetRedir(true)
                    }
                })
                .catch(function (response) {

                    SetError('Something went wrong')
                    if (!(email_invalid === false && email !== '')){
                        SetEmail('')
                    }

                    if (!psw_comp || !psw_equal || psw_invalid || psw_check_invalid){
                        SetPassword('')
                        SetPasswordVal('')
                        SetPasswordValUp('')
                        var div_length = document.getElementById('length-psw')
                        div_length.setAttribute('class','not-satisfied')

                        var div_length = document.getElementById('number-psw')
                        div_length.setAttribute('class','not-satisfied')

                        var div_length = document.getElementById('upper-psw')
                        div_length.setAttribute('class','not-satisfied')
                        var div_length = document.getElementById('special-psw')
                        div_length.setAttribute('class','not-satisfied')
                    }
                    if (name_invalid){
                        SetName('')
                    }
                    if (surname_invalid){
                        SetSurname('')
                    }
                    if(badge_invalid){
                        SetBadge('')
                    }
                    SetRedir(false)


                });
        }else {
            if (!(email_invalid === false && email !== '')){
                SetEmail('')
            }

            if (!psw_comp || !psw_equal || psw_invalid || psw_check_invalid){
                SetPassword('')
                SetPasswordVal('')
                SetPasswordValUp('')
                var div_length = document.getElementById('length-psw')
                div_length.setAttribute('class','not-satisfied')

                var div_length = document.getElementById('number-psw')
                div_length.setAttribute('class','not-satisfied')

                var div_length = document.getElementById('upper-psw')
                div_length.setAttribute('class','not-satisfied')
                var div_length = document.getElementById('special-psw')
                div_length.setAttribute('class','not-satisfied')
            }
            if (name_invalid){
                SetName('')
            }
            if (surname_invalid){
                SetSurname('')
            }
            if(badge_invalid){
                SetBadge('')
            }



            SetFormData(false)
        }




    }
    function handleChangeName(e){
        e.preventDefault()

        SetName(e.currentTarget.value)


    }
    function handleChangeSurname(e){
        e.preventDefault()

        SetSurname(e.currentTarget.value)


    }
    function handleChangeBadge(e){
        e.preventDefault()

        SetBadge(e.currentTarget.value)


    }
    function handleChangeEmail(e){
        e.preventDefault()
        SetError('')
        SetEmailInvalid(false)
        console.log('target',e.currentTarget.value)
        SetEmail(e.currentTarget.value)


    }


    const handlePasswordChange = (event) => {
        const passwordValue = event.target.value;
        SetPasswordVal(passwordValue);
        SetError("")
        SetPswCompliant(true)
        SetPswEqual(true)
        var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        var special = regex.test(passwordValue);
        var len = passwordValue.length >= 8
        var number = /[0-9]/.test(passwordValue)
        var upper = /[A-Z]/.test(passwordValue)

        var div_length = document.getElementById('length-psw')
        if (len){
            div_length.setAttribute('class','satisfied')
        }
        else{
            div_length.setAttribute('class','not-satisfied')
        }

        var div_length = document.getElementById('number-psw')
        if (number){
            div_length.setAttribute('class','satisfied')
        }else{
            div_length.setAttribute('class','not-satisfied')
        }

        var div_length = document.getElementById('upper-psw')
        if (upper){
            div_length.setAttribute('class','satisfied')
        }else{
            div_length.setAttribute('class','not-satisfied')
        }

        var div_length = document.getElementById('special-psw')
        if (special){
            div_length.setAttribute('class','satisfied')
        }else{
            div_length.setAttribute('class','not-satisfied')
        }




    };

    const handlePasswordUpChange = (event) => {
        SetError("")
        SetPswEqual(true)

        const passwordValue = event.target.value;
        SetPasswordValUp(passwordValue)

    };


    function handleClose(){
        SetShowModalEmail(false)
    }
    return (
        <div className="App">
            {Redir === true && Email && UserType && <Redirect to='/my-courses'/>}

            <Dialog
                open={ShowModalEmail}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Your email does not belong to the University of Padua"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are providing an email whose domain is not <b>studenti.unipd.it, unipd.it, dei.unipd.it</b>, sign up anyway?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Change email</Button>
                    <Button onClick={(e)=>{
                        handleSubmit(e,true)
                    }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

                <Container fluid>



                            <div className={'reglog'}>
                                <div><img className={'login'} src={"http://crane.dei.unipd.it/static/img/cutlogostart.png"} />
                                </div>
                                <div style={{textAlign:'center'}}><h4>Signup</h4>
                                </div>
                                    {Error !== '' && <div style={{width:'30vw',display:"inline-block"}}><Alert severity="error">{Error}</Alert></div>}

                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <FormControl>
                                    <div style={{marginTop:'3vh',width:'350px'}}>
                                        <TextField sx={{ width:'inherit' }}   size="small"
                                                   onChange={(e)=>handleChangeEmail(e)}
                                                   name="email"

                                                   required
                                                   id="email-standard-basic"
                                                   label="Email"
                                                   variant="outlined"
                                                   error={EmailInvalid}
                                                   helperText={EmailInvalid && "Invalid email"}
                                        />
                                        <div className={'footnote'}>Please, add your email, preferably your official <b>@studenti.unipd.it</b> one
                                        </div>
                                    </div>
                                    <div style={{marginTop:'3vh',width:'350px'}}>
                                        <TextField sx={{ width:'inherit' }}   size="small"
                                                   value={Name}
                                                   onChange={(e)=>handleChangeName(e)}
                                                   name="name"
                                                   required
                                                   id="name-standard-basic"
                                                   label="Name"
                                                   variant="outlined"

                                        />
                                    </div>
                                    <div style={{marginTop:'3vh',width:'350px'}}>
                                        <TextField sx={{ width:'inherit' }}   size="small"
                                                   value={Surname}
                                                   onChange={(e)=>handleChangeSurname(e)}
                                                   name="surname"
                                                   required
                                                   id="surname-standard-basic"
                                                   label="Surname"
                                                   variant="outlined"

                                        />
                                    </div>
                                    <div style={{marginTop:'3vh',width:'350px'}}>
                                        <TextField sx={{ width:'inherit' }}   size="small"
                                                   value={Badge}
                                                   onChange={(e)=>handleChangeBadge(e)}
                                                   name="badge"
                                                   type="number"
                                                   id="badge-standard-basic"
                                                   label="Badge"
                                                   variant="outlined"

                                        />
                                        <div className={'footnote'}>optional field: you can update it later on, if you have not a badge yet</div>

                                    </div>
                                    <div style={{marginTop:'3vh',width:'350px'}}>

                                        <TextField
                                            id="password"
                                            name="password"
                                            label="Password"
                                            required
                                            type="password"
                                            size={'small'}
                                            value={PasswordVal}
                                            sx={{ width:'inherit' }}
                                            onChange={handlePasswordChange}
                                            error={PswCompliant === false}
                                            helperText={PswCompliant === false && "The password does not comply with the requirements"}
                                        />
                                        <div className={'footnote'}>
                                            <div id={'length-psw'} className={'not-satisfied'}>8 characters</div>
                                            <div id={'upper-psw'} className={'not-satisfied'}>1 upper case letter</div>
                                            <div id={'special-psw'} className={'not-satisfied'}>1 special character</div>
                                            <div id={'number-psw'} className={'not-satisfied'}>1 number</div>

                                        </div>
                                        <br/>
                                            <TextField
                                                id="password_check"
                                                name="password_check"
                                                required
                                                label="Insert password again"
                                                type="password"
                                                size={'small'}
                                                value={PasswordValUp}
                                                sx={{ width:'inherit' }}
                                                onChange={handlePasswordUpChange}
                                                error={PswEqual === false}
                                                helperText={PswEqual === false && "The passwords are not equal"}
                                            />
                                    </div>
                                    <Button type="submit"  sx={{ '& > :not(style)': { m: 1 },background:"linear-gradient(90deg, rgb(90, 8, 8) 0%, rgb(90, 8, 8) 100%)" }} size={'large'}
                                            variant="contained" style={{marginTop:'5vh',width:'inherit'}} >Sign Up</Button>
                                </FormControl>
                            </Box>
                            <br/>
                            <div style={{marginTop:'2vh'}}>
                                <Link href={window.location.origin+"/login"} variant="body2">
                                    {"Already have an account? Log in"}
                                </Link>
                            </div>

                        </div>

                </Container>

        </div>
    );
}



export default SignUp;
