import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import './headerbar.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'

// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect} from "react-router-dom";
import {Autocomplete, Avatar, Chip, Collapse, Divider, IconButton, ListItemIcon, Menu, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Logout,Settings} from "@mui/icons-material";
import {styled} from "@mui/material/styles";


function HeaderBar(props) {


    const { academicYears,email,studentCourses,username,usertype } = useContext(AppContext);
    const [Username,SetUsername] = username
    const [UserType,SetUserType] = usertype



    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const CoursesButton = styled(Button)(({ theme }) => ({
            color: 'white',
            margin: '0 2%',
            '&:hover': {
                color: '#941010',
                margin: '0 2%',
            },
    }))



    return (
        <div className={'headerbar'}>

            <div className={'firstheader'}>
                <Box sx={{ display: 'flex', alignItems: 'center', height:'inherit',textAlign: 'center',float:'left' }}>

                <div className={'logocrane'}><a className={'linklogo'} href={window.location.origin}><b>CRANE</b></a></div>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', height:'inherit',textAlign: 'center',float:'right' }}>

                        <CoursesButton
                            href={window.location.origin+"/my-courses"}>Courses</CoursesButton>

                        <Tooltip title={Username}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                    <Avatar sx={{ width: 32, height: 32 }}></Avatar>

                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                        {UserType && UserType.toLowerCase() === 'student' && <MenuItem onClick={handleClose}>
                            <a className={'linkbut'} href={window.location.origin + "/profile"}>

                                <ListItemIcon>
                                    <Settings fontSize="small"/>
                                </ListItemIcon>
                                Settings
                            </a>
                        </MenuItem>}
                        <MenuItem onClick={handleClose}>
                            <a className={'linkbut'} href={window.location.origin+"/logout"}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout</a>
                        </MenuItem>
                    </Menu>
            </div>

        </div>
    );
}


export default HeaderBar;
