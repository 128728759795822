// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course{
    margin-bottom: 3%;
}
.profcourse{
    margin-bottom:2%;
}
.Button:disabled {
    color: darkgreen;
    border: darkgreen;
}
.year{
    margin: 3%;
}
.addcourse{
    margin: 2% 0;
}
.enrollerror{
    color:darkred;
    font-size: 0.8rem;
}

.groupcard{
    margin-top:2%
}`, "",{"version":3,"sources":["webpack://./src/components/courses/course.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,UAAU;AACd;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI;AACJ","sourcesContent":[".course{\n    margin-bottom: 3%;\n}\n.profcourse{\n    margin-bottom:2%;\n}\n.Button:disabled {\n    color: darkgreen;\n    border: darkgreen;\n}\n.year{\n    margin: 3%;\n}\n.addcourse{\n    margin: 2% 0;\n}\n.enrollerror{\n    color:darkred;\n    font-size: 0.8rem;\n}\n\n.groupcard{\n    margin-top:2%\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
