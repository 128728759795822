import {useParams} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import {
    SearchState,
    FilteringState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    IntegratedSelection,
    DataTypeProvider,


} from '@devexpress/dx-react-grid';
import Button from "@mui/material/Button";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
var FileDownload = require('js-file-download');
import {
    Grid,
    Table,
    SearchPanel,
    TableHeaderRow,
    TableRowDetail,
    TableFilterRow,
    VirtualTable,
    DragDropProvider,
    TableColumnReordering,
    Toolbar,
    PagingPanel,
    TableEditColumn,
    ColumnChooser,
    TableSelection,
    TableColumnVisibility,
    TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faProjectDiagram, faPencilAlt, faComment} from "@fortawesome/free-solid-svg-icons";
import {AppContext} from "../../App";

import React, {useState, useEffect, useContext, createContext, useRef} from "react";
import axios from "axios";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import TextField from "@mui/material/TextField";

export default function Summary(props) {
    const {
        academicYears,
        curCourse,
        email,
        studentCourses,
        courseslist,
        studentDegree,
        degrees,
        tasks
    } = useContext(AppContext);

    const [StudentCourses, SetStudentCourses] = studentCourses
    const [AcademicYears, SetAcademicYears] = academicYears
    const [Error, SetError] = useState(false)
    const [Update, SetUpdate] = useState(false)
    const [Rows, SetRows] = useState([])
    const [OpenComment,SetOpenComment] = useState(false)
    const [Columns, SetColumns] = useState([])
    // const [Columns, SetColumns] = useState([{name: 'name', title: 'Name'},{name: 'description', title: 'Description'},{name: 'members', title: 'Members'}])
    const [CurTask, SetCurTask] = useState(false)
    // const [defaultColumnWidths, setdefaultColumnWidths] = useState([{columnName: 'name', width: 200},{columnName: 'description', width: 200},{columnName: 'members', width: 200}])
    const [defaultColumnWidths, setdefaultColumnWidths] = useState(false)
    const {id} = useParams();


    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElTask, setAnchorElTask] = useState(null);
    const [OpenEval,SetOpenEval] = useState(false)
    const open = Boolean(anchorEl);
    const [deleteCols] = useState(['homework']);
    const [evalCols] = useState(['evaluate_homework']);
    const [evalupCols] = useState(['evaluation']);
    const [CurCourse,SetCurCourse] = curCourse
    const [Score,SetScore] = useState(null)
    const [Comment,SetComment] = useState(null)
    const [RowToEval,SetRowToEval] = useState(false)
    const [Evaluation,SetEvaluation] = useState(false)

    const [Attachment,SetAttachment] = useState(false)
    const [AttachmentName,SetAttachmentName] = useState(false)

    const [tableColumnVisibilityColumnExtensions] = useState([{
            columnName: 'homework',
            showInColumnChooser: false
        }, {columnName: 'evaluate_homework', hidden: true},{columnName: 'evaluation', hidden: true},{columnName: 'update', hidden: true},
    ]);


    const [FilterExt] = useState([{
        columnName: 'homework',
        filteringEnabled: false,
        SortingEnabled: false
    }, {columnName: 'evaluate_homework', filteringEnabled: false, SortingEnabled: false}, {columnName: 'update', filteringEnabled: false, SortingEnabled: false}, {columnName: 'evaluation', filteringEnabled: false, SortingEnabled: false}])
    const [ResizeExt] = useState([{columnName: 'evaluate_homework', minWidth: 200, maxWidth: 200}])
    const [sortingStateColumnExtensions] = useState([
        {columnName: 'homework', sortingEnabled: false}, {columnName: 'evaluate_homework', sortingEnabled: false},{columnName: 'evaluation', sortingEnabled: false},{columnName: 'update', sortingEnabled: false},
    ]);
    const [pageSizes] = useState([5, 10, 25, 50, 0]);



    // useEffect(() => {
    //     axios.get("courses",{params:{type:'professor',courseid:id}}).then(response=>{
    //         var res = response.data['courses'].filter(c => c.is_alias === false)
    //         Setprops.curcourse(res[0])
    //
    //     }).catch(error=>console.log(error))
    // }, [])

    useEffect(() => {
            axios.get('get_summary', {params: {courseid: props.curcourse.id}}).then(response => {
                SetRows(response.data['rows'])

                var cols = response.data['cols']

                SetColumns(cols)


                SetUpdate(false)
            })


    }, [])


    useEffect(()=>{
        if(Columns.length > 0){
            var wd = Columns.map(c=>({'columnName':c.name,wordWrapEnabled: true}))
            setdefaultColumnWidths(wd)
        }
    },[Columns])





    function handleCloseSnack(){
        SetError(false)
    }

    
    const FilterCell = (props) => {
        const { column } = props;
        if(column.name === 'name' || column.name === 'description' || column.name === 'cur_state' || column.name === 'members'){
            return <TableFilterRow.Cell {...props} />;

        }
        else
        {

            return <th className="MuiTableCell-root MuiTableCell-head" style={{borderBottom:'1px solid rgb(224, 224, 224)'}}> </th>
        }

    };


    const GroupCommentFormatter = ({row}) =>
        <div>

            {(row.comment !== '' && row.comment) ? <Button onClick={() => SetOpenComment(row.comment)}><FontAwesomeIcon icon={faComment}
                                                                                                       color='#757575'/></Button> : <div>No comment</div>
            }



        </div>
    const GroupCommentProvider = props => (
        <DataTypeProvider
            formatterComponent={GroupCommentFormatter}
            {...props}
        />
    );

    function downloadEvaluation(e,row){
        e.preventDefault()

        axios.get('/download_eval',{params:{taskid:row.taskid,groupid:row.groupid,studentid:row.studentid},responseType: 'blob'})
            .then(response => {
                // Creating the blob file and its url
                var blob = new Blob([response.data]);
                let url = window.URL.createObjectURL(blob);


                // Creating the hyperlink and auto click it to start the download
                let link = document.createElement('a');
                link.href = url;
                link.download = row.attachment
                link.click();
                //window.location.href = response.url;
            });


        console.log('download eval')
    }
    const DownloadFormatter = ({row}) =>
        <div>

            {row.attachment ? <Button className='opt_but' size='sm' onClick={(e) => downloadEvaluation(e, row)}>{row.attachment}</Button> : <div></div>}

        </div>

    const DownloadProvider = props => (
        <DataTypeProvider
            formatterComponent={DownloadFormatter}
            {...props}
        />
    );

    return(
        <div>
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            {OpenComment && <Dialog
                open={OpenComment}
                onClose={()=> {
                    SetOpenComment(false);
                }}
                fullWidth
                maxWidth="lg"

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                        <div>
                            <div style={{whiteSpace:'pre-line'}}>{OpenComment}</div>
                            <br/><hr/>
                        </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {
                        SetOpenComment(false);
                    }}>Close</Button>


                </DialogActions>
            </Dialog>}


            {props.curcourse ? <div>
                {/*{OpenInfoModal && CurID && CurAnnotation && <InfoModal show={OpenInfoModal} setshow={SetOpenInfoModal} curid={CurID} annotation={CurAnnotation}  row={props.righe} />}*/}
                {/*{OpenContentModal && Content && <ContentModal show={OpenContentModal} setshow={SetOpenContentModal} content={Content} />}*/}
                {/*{OpenDeleteModal && IdToDel && <DeleteDocumentModal show={OpenDeleteModal} setshow={SetOpenDeleteModal} doc={IdToDel} setconfirm={SetConfirmDelete}/>}*/}
                {/*{OpenDownloadModal && IdToDownload && <DownloadModal show={OpenDownloadModal} setshow={SetOpenDownloadModal} doc={IdToDownload}/>}*/}
                <div style={{padding: '2%'}}>
                    {Rows && Columns.length > 0 && defaultColumnWidths.length === Columns.length && <Grid
                        rows={Rows}
                        columns={Columns}
                    >


                        <SearchState/>
                        <PagingState
                            defaultCurrentPage={0}
                            defaultPageSize={25}
                        />
                        <GroupCommentProvider for={['comment']}/>
                        <DownloadProvider for={['attachment']}/>

                        <FilteringState columnExtensions={FilterExt} defaultFilters={[]}/>
                        <IntegratedFiltering/>
                        <SortingState defaultSorting={[{columnName: 'name', direction: 'asc'},]}
                                      columnExtensions={sortingStateColumnExtensions}
                        />
                        <IntegratedSorting/>
                        <IntegratedPaging/>
                        <Table columnExtensions={defaultColumnWidths} />
                        {/*<TableColumnResizing defaultColumnWidths={defaultColumnWidths}*/}
                        {/*                     />*/}
                        <TableHeaderRow/>

                        {/*<TableSelection showSelectAll />*/}
                        <TableFilterRow
                            cellComponent={FilterCell}
                        />
                        <PagingPanel
                            pageSizes={pageSizes}
                        />
                        <TableColumnVisibility
                            columnExtensions={tableColumnVisibilityColumnExtensions}
                        />


                    </Grid>}
                        </div>

            </div> :
                <div className={'circularbar'}><CircularProgress /></div>}
        </div>

    );
}
