import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect, useParams} from "react-router-dom";
import {
    Autocomplete,
    Chip,
    CircularProgress,
    Collapse, createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, FormControlLabel, FormGroup, Paper, Radio, RadioGroup, Snackbar, withStyles

} from "@mui/material";
import '../../App.css';

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
function TaskModal(props) {


    const { groupinfo,academicYears,studentCourses,email,degree,info,taskinfo,courseslist,studentDegree,degrees,group,curCourse } = useContext(AppContext);
    const [TaskName,SetTaskName] = useState("")
    const [TaskDesc,SetTaskDesc] = useState("")
    const [TaskDeadline,SetTaskDeadline] = useState(null)
    const [TaskFromDeadline,SetTaskFromDeadline] = useState(null)
    const [TaskOptional,SetTaskOptional] = useState(null)
    const [TaskIndividual,SetTaskIndividual] = useState(null)
    const [TaskComputational,SetTaskComputational] = useState(null)
    const [TaskAssessment,SetTaskAssessment] = useState(null)
    const [AttRequired,SetAttRequired] = useState(null)
    const [MinScore, SetMinScore] = useState(null)
    const [MaxScore, SetMaxScore] = useState(null)
    const [Error,SetError] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse

    useEffect(()=>{
        if(props.task){
            SetTaskName(props.task.name)
            SetTaskDesc(props.task.description)
            SetTaskDeadline(dayjs(props.task.taskdeadline))
            SetTaskFromDeadline(dayjs(props.task.taskfromdeadline))
            const numbers = props.task.scorerange.match(/\d+/g).map(Number);
            const [min, max] = numbers;
            SetMinScore(min)
            SetMaxScore(max)
            SetTaskOptional(props.task.optional ? "Optional" : "Mandatory")
            SetTaskIndividual(props.task.individual ? "Individual" : "Group")
            SetTaskComputational(props.task.computational ? "Computational" : "HW")
            if (props.task.assessment){
                SetTaskAssessment("Assess")
            }else{
                SetTaskAssessment("NoAssess")
            }
            SetAttRequired(props.task.attach_file ? "Att" : "NoAtt")
        }else{
            SetTaskDeadline(dayjs())
            SetTaskFromDeadline(dayjs())
        }
    },[])



    function createTask(){
            if(TaskName.length > 0 && TaskDesc.length > 0 && MinScore !== MaxScore !== "" && MinScore <= MaxScore && TaskDeadline && TaskFromDeadline && TaskAssessment !== null && (TaskFromDeadline.diff(TaskDeadline) <= 0) && TaskComputational !== null && ((AttRequired && TaskComputational === "HW") || TaskComputational === "Computational") && TaskIndividual !== null && TaskOptional !== null){
                var t = {}
                t['courseid'] = CurCourse.id
                t['name'] = TaskName
                t['description'] = TaskDesc
                t['deadline'] = TaskDeadline.format('YYYY-MM-DD').toString()
                t['from_deadline'] = TaskFromDeadline.format('YYYY-MM-DD').toString()
                t['minscore'] = MinScore
                t['maxscore'] = MaxScore
                if(props.task){
                    t['id'] = props.task.id

                }
                t['individual'] = TaskIndividual
                t['optional'] = TaskOptional
                t['computational'] = TaskComputational
                t['attachment_required'] = "NoAtt"
                t['assessment'] = TaskAssessment
                if(TaskComputational === "HW"){
                    t['attachment_required'] = AttRequired

                }
                axios.post("/task",{task:t})
                    .then(response=>{
                        if(response.data['error']){
                            SetError(response.data['error'])
                        }else{
                            props.setopen(false)
                            props.setnewtask(true)
                        }

                    }).catch(error=>{
                    SetError('an error occurred updating the course.')
                })
            }else{
                SetError('task name, description, min and max score must be non empty, and all the features; min score must be lower than max score')
            }


    }





    // function handleCloseModify(){
    //     SetCourseToMod(false)
    //     SetModifyCourse(false)
    // }





    useEffect(()=>{
        console.log('error',Error)
    },[Error])
    return (
        <div>



            <Dialog
                open={props.open}
                keepMounted
                disableBackdropClick
                maxWidth="lg"
                fullWidth
                sx = {{height:'100vh',overflowY:'scroll'}}
                onClose={()=>props.setopen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                {props.task ? <DialogTitle><h2>{"Modify task: "}{props.task.name}</h2></DialogTitle> : <DialogTitle><h2>New Task</h2></DialogTitle>}
                <DialogContent>
                    <div>
                        <div style={{textAlign:'center',margin:'2%'}}>
                            <Row>

                                <Col md={12}>
                                    <div style={{margin:'2%'}}>

                                        <TextField id="name" label="Name" value={TaskName} sx={{width:'100%'}}  variant="outlined"
                                                   onChange={(e)=>{
                                                       var name = (e.target.value);
                                                       SetTaskName(name)
                                                   }}/>

                                    </div>
                                    <div style={{margin:'2%'}}>
                                        <TextField id="desc" label="Description" value={TaskDesc} sx={{width:'100%'}}  variant="outlined"
                                                   onChange={(e)=>{
                                                       var desc = (e.target.value);
                                                       SetTaskDesc(desc)
                                                   }}/>

                                    </div>
                                    <div style={{margin:'2%'}}>
                                        <div>Set the date range where students have to work on the task.</div>
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <div style={{margin:'2%',float:'left'}}>
                                                {TaskFromDeadline && <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker sx={{width: '100%'}}
                                                                defaultValue={TaskFromDeadline}
                                                                disablePast
                                                                label="From"
                                                                onChange={(newValue) => SetTaskFromDeadline(newValue)}/>
                                                </LocalizationProvider>}
                                            </div>
                                            <div style={{margin:'2%',float:'left'}}>

                                                {TaskDeadline && <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker sx={{width: '100%'}}
                                                                defaultValue={TaskDeadline}
                                                                disablePast
                                                                label="To"
                                                                onChange={(newValue) => SetTaskDeadline(newValue)}/>
                                                </LocalizationProvider>}
                                            </div>

                                        </div>
                                        {/*{TaskDeadline && <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                                        {/*    <DatePicker sx={{width: '100%'}}*/}
                                        {/*                defaultValue={TaskDeadline}*/}
                                        {/*                disablePast*/}
                                        {/*                label="Submission deadline"*/}
                                        {/*                onChange={(newValue) => SetTaskDeadline(newValue)}/>*/}
                                        {/*</LocalizationProvider>}*/}
                                    </div>
                                    <div>
                                        <div style={{textAlign:'left'}}>Set the minimum and maximum scores students can obtain with this task</div>
                                        <div style={{margin:'2%',display:'inline-block',width:'45%'}}>
                                            <TextField
                                                id="minrange"
                                                label="Min score"
                                                sx={{width:'100%'}}
                                                type="number"
                                                value={MinScore}
                                                onChange={(e)=>{
                                                    e.preventDefault();
                                                    var value = e.target.value
                                                    if (value !== ""){
                                                        SetMinScore(parseInt(value))
                                                }}}
                                                // error={MaxScore < MinScore}
                                                // helperText={(MaxScore < MinScore) && "Max score must be greater than min score"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        <div style={{margin:'2%',display:'inline-block',width:'45%'}}>
                                            <TextField
                                                id="maxrange"
                                                label="Max score"
                                                sx={{width:'100%'}}
                                                type="number"
                                                value={MaxScore}
                                                onChange={(e)=>{
                                                    e.preventDefault();
                                                    var value = e.target.value
                                                    if (value !== ""){
                                                        SetMaxScore(parseInt(value))
                                                    }}}
                                                // error={MaxScore < MinScore}
                                                // helperText={(MaxScore < MinScore) && "Max score must be greater than min score"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <h4>Task features</h4>
                                        <div>
                                            <div>
                                                <div style={{textAlign:'left'}}>Is this an <b>assessment</b> task? When an assessment task is open, students can evaluate each other work.</div>

                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={TaskAssessment}
                                                    onChange={(e)=>{
                                                        e.preventDefault()
                                                        if (e.target.value === "Assess") {
                                                            SetTaskAssessment("Assess")
                                                        }else {
                                                            SetTaskAssessment("NoAssess")

                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value="Assess" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="NoAssess" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </div><hr/>
                                            <div style={{textAlign:'left'}}>Individual tasks have to be performed by each student. Conversely, group tasks must be solved by groups of students.</div>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={TaskIndividual}
                                                onChange={(e)=>{
                                                    e.preventDefault()
                                                    if (e.target.value === "Individual") {
                                                        SetTaskIndividual("Individual")
                                                    }else {
                                                        SetTaskIndividual("Group")

                                                    }
                                                }}
                                            >
                                                <FormControlLabel
                                                                    value="Individual" control={<Radio />} label="Individual" />
                                                <FormControlLabel value="Group" control={<Radio />} label="Group" />
                                            </RadioGroup>
                                        </div>

                                        <div>
                                            <div style={{textAlign:'left'}}>Optional tasks can be omitted. Conversely, mandatory tasks must be solved and submitted.</div>

                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={TaskOptional}
                                                onChange={(e)=>{
                                                    e.preventDefault()
                                                    if (e.target.value === "Optional") {
                                                        SetTaskOptional("Optional")
                                                    }else {
                                                        SetTaskOptional("Mandatory")

                                                    }
                                                }}
                                            >
                                                <FormControlLabel
                                                                    value="Optional" control={<Radio />} label="Optional" />
                                                <FormControlLabel value="Mandatory" control={<Radio />} label="Mandatory" />
                                            </RadioGroup>
                                        </div>
                                        <div>
                                            <div style={{textAlign:'left'}}>Computational tasks are all the tasks where students are not required to act on: for example final scores publication. </div>

                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={TaskComputational}
                                                onChange={(e)=>{
                                                    e.preventDefault()
                                                    if (e.target.value === "Computational") {
                                                        SetTaskComputational("Computational")
                                                    }else {
                                                        SetTaskComputational("HW")

                                                    }
                                                }}
                                            >
                                                <FormControlLabel

                                                                    value="Computational" control={<Radio />} label="Computational" />
                                                <FormControlLabel value="HW" control={<Radio />} label="Scoring" />
                                            </RadioGroup>

                                        </div>
                                        {TaskComputational === "HW" && <div>
                                            <div style={{textAlign: 'left'}}>Select if the students must or must not add
                                                an attachment (a file with their homework).
                                            </div>

                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                value={AttRequired}
                                                onChange={(e) => {
                                                    e.preventDefault()
                                                    if (e.target.value === "Att") {
                                                        SetAttRequired("Att")
                                                    } else {
                                                        SetAttRequired("NoAtt")

                                                    }
                                                }}
                                            >
                                                <FormControlLabel

                                                    value="NoAtt" control={<Radio/>} label="No attachment"/>
                                                <FormControlLabel value="Att" control={<Radio/>}
                                                                  label="Attachment required"/>
                                            </RadioGroup>

                                        </div>}
                                    </div>




                                </Col>
                            </Row>


                        </div>




                    </div>
                </DialogContent>
                {Error && <div style={{padding:'2%'}}><b>{Error}</b></div>}

                <DialogActions>
                    <Button onClick={(e)=>{
                        e.preventDefault()
                        props.setopen(false)
                    }}>Cancel</Button>
                    <Button onClick={createTask}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>

    );

}

export default TaskModal;
