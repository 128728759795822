// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstheader{
    height: 50px;
    background-color: #232323;
    padding: 0 2%;
}
.secondheader{
    margin-top: 2%;
    height: 50px;

}

.MuiDivider-wrapper css-c1ovea{
    display: contents;
}
.logocrane{
    text-align: center;
    color:white;
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
}
.linklogo,.linklogo:hover{
    color:white !important;
    text-decoration: none !important;
}
.linkbut{
    color:black;
    cursor: pointer;
}
.linkbut:hover{
    color:black;
    cursor: pointer;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/utils/headerbar.css"],"names":[],"mappings":"AAGA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;AACjB;AACA;IACI,cAAc;IACd,YAAY;;AAEhB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,kCAAkC;AACtC;AACA;IACI,sBAAsB;IACtB,gCAAgC;AACpC;AACA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,WAAW;IACX,eAAe;IACf,qBAAqB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');\n\n\n.firstheader{\n    height: 50px;\n    background-color: #232323;\n    padding: 0 2%;\n}\n.secondheader{\n    margin-top: 2%;\n    height: 50px;\n\n}\n\n.MuiDivider-wrapper css-c1ovea{\n    display: contents;\n}\n.logocrane{\n    text-align: center;\n    color:white;\n    font-size: 1.4rem;\n    font-family: 'Poppins', sans-serif;\n}\n.linklogo,.linklogo:hover{\n    color:white !important;\n    text-decoration: none !important;\n}\n.linkbut{\n    color:black;\n    cursor: pointer;\n}\n.linkbut:hover{\n    color:black;\n    cursor: pointer;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
