import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import {Container,Row,Col} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import './profile.css'
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from "@mui/material/MenuItem";
import {
    CircularProgress,
    DialogTitle, Divider, ListItemIcon, Menu, Snackbar, ThemeProvider
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import axios from "axios";
import {Redirect} from "react-router-dom";
import TextField from "@mui/material/TextField";


function Profile() {


    const { username,group,email,studentCourses,courseslist,studentDegree,degrees,curCourse } = useContext(AppContext);

    const [Badge,SetBadge] = useState("")
    const [Name,SetName] = useState("")
    const [Surname,SetSurname] = useState("")
    const [Email,SetEmail] = useState("")
    const [StuEmail,SetStuEmail] = email
    const [ProfileInfo,SetProfileInfo] = useState(false)
    const [Error, SetError] = useState(false)
    const [Username,SetUsername] = username

    useEffect(()=>{
        if(StuEmail && Name === "" && Surname === "" && Email === ""){
            axios.get('/profile_info')
                .then(response=>
                {
                    SetName(response.data['profile']['name'])
                    SetSurname(response.data['profile']['surname'])
                    SetEmail(response.data['profile']['email'])
                    SetBadge(response.data['profile']['badge'])
                }  )
                .catch(error=>console.log(error))
        }

    },[StuEmail])

    function updateinfo(){
        axios.post('/profile_info',{badge:Badge,name:Name,surname:Surname,email:Email})
            .then(response=> {
                SetError("Updated");
                SetStuEmail(Email)
            })
            .catch(error=>SetError('An error occurred'))
    }


    function handleCloseSnack(){
        SetError(false)
    }

    return (
        <div>

            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <HeaderBar />

            {(Name && Surname  && Email) ? <div style={{textAlign:'center',margin:'2%'}}>
               <h1>
                   {window.username}
               </h1>
                <div style={{margin:'3%'}}>
                    <div className={'formfield'}>
                        <div><b>Email</b></div>
                        <div>
                            <TextField sx={{width:"700px"}}
                                       id="email-textfield"
                                       label="Email"
                                       value={Email}
                                       onChange={(e)=>SetEmail(e.target.value)}

                                       variant="outlined" />
                        </div>
                    </div>
                    <div className={'formfield'}>
                        <div><b>Name</b></div>
                        <div>
                            <TextField sx={{width:"700px"}}
                                       id="name-textfield"
                                       label="Name"
                                       value={Name}
                                       onChange={(e)=>SetName(e.target.value)}

                                       variant="outlined" />
                        </div>
                    </div>
                    <div className={'formfield'}>
                        <div><b>Surname</b></div>
                        <div>
                            <TextField sx={{width:"700px"}}
                                       id="surname-textfield"
                                       label="Surname"
                                       value={Surname}
                                       onChange={(e)=>SetSurname(e.target.value)}

                                       variant="outlined" />
                        </div>
                    </div>
                    <div className={'formfield'}>
                        <div><b>Badge</b></div>
                        <div>
                            <TextField sx={{width:"700px"}}
                                       id="badge-textfield"
                                       label="Badge"
                                       value={Badge}
                                       onChange={(e)=>SetBadge(e.target.value)}
                                       variant="outlined" />
                        </div>
                    </div>
                    <Button sx={{margin:'4%'}} variant={'contained'} size={'large'} color='error' onClick={updateinfo}>Update</Button>


                </div>
            </div> : <div className={'circularbar'}><CircularProgress /></div>}
        </div>

    );
}


export default Profile;
