import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext, useRef} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import './task.css';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect, useParams} from "react-router-dom";
import {
    Autocomplete,
    Chip,
    TextField,
    CircularProgress,
    Collapse, createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, FormControlLabel, FormGroup, Paper, Radio, RadioGroup, Snackbar, withStyles

} from "@mui/material";
import '../../App.css';

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
function Formula(props) {


    const { groupinfo,academicYears,studentCourses,email,degree,info,taskinfo,courseslist,studentDegree,degrees,group,curCourse } = useContext(AppContext);
    const [TaskName,SetTaskName] = useState("")
    const [TaskDesc,SetTaskDesc] = useState("")
    const [Selected,SetSelected] = useState([])
    const [TaskFromDeadline,SetTaskFromDeadline] = useState(null)
    const [TaskOptional,SetTaskOptional] = useState(null)
    const [TaskIndividual,SetTaskIndividual] = useState(null)
    const [TaskComputational,SetTaskComputational] = useState(null)
    const [TaskAssessment,SetTaskAssessment] = useState(null)
    const [AttRequired,SetAttRequired] = useState(null)
    const [MinScore, SetMinScore] = useState(null)
    const [MaxScore, SetMaxScore] = useState(null)
    const [Error,SetError] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [Options,SetOptions] = useState([])
    const [OptionsDisabled,SetOptionsDisabled] = useState([])
    const [TasksList,SetTasksList] = useState([])
    const [FormulaOb,SetFormulaOb] = useState([null,null])

    const [opt,setOpt] = useState([])

    useEffect(()=>{
       axios.get('/get_student_list',{params:{course:CurCourse.id}})
           .then(response=>{
               var options = []
               response.data['students'].map(student=>{
                   options.push(student)
               })

               SetOptions(options)
               setOpt(options)
           }).catch(error=>console.log(error))

        axios.get('/get_tasks_list',{params:{course:CurCourse.id}})
            .then(response=>{
                var tasks = []
                response.data['tasks'].map(student=>{
                    tasks.push(student)
                })
                SetTasksList(tasks)
            }).catch(error=>console.log(error))

    },[])





    const [value, setValue] = useState('');
    const [cursorPosition, setCursorPosition] = useState(null);

    const handleInsertValue = (e,val) => {
        var textToInsert =val;
        const match = val.match(/\[(.*?)\]/);
        if (match && match[1]) {
            textToInsert = match[1];
        }

        const input = document.getElementById('formula_input_'+props.id);
        if (cursorPosition !== null) {
            const start = cursorPosition;
            const newValue = value.slice(0, start) + textToInsert + value.slice(start);
            input.value = newValue;

            // Update the cursor position
            setTimeout(() => {
                input.selectionStart = input.selectionEnd = start + textToInsert.length;
                input.focus();
            }, 0);
        }
    };

    const handleChange = (event) => {
        setValue(event.target.value);
        SetFormulaOb([FormulaOb[0],event.target.value])

    };
    useEffect(()=>{
        if(FormulaOb[0] !== null && FormulaOb[0] !== undefined && FormulaOb[1] !== null && FormulaOb[0] !== undefined ){
            props.setformula([...props.formula,FormulaOb])
        }
    },[FormulaOb])
    const handleCursorChange = (event) => {
        setCursorPosition(event.target.selectionStart);
    };

    useEffect(()=>{
        console.log('error',Error)
    },[Error])


    return (
        <div>


                <div style={{margin:'2%'}} className={"formula"} id={"formula_"+props.id}>
                    <h4>New formula</h4>
                    <Autocomplete
                        multiple
                        sx={{width:'100%',marginTop:'2%'}}
                        options={Options}

                        getOptionLabel={(option) => option.name}
                        onChange={(e,newValue)=>{
                            console.log(newValue)
                            var found=false
                            var id = ''
                            newValue.map(val=>{
                                if(id !== ''){
                                    id = id +'_'+ val.id.toString()
                                }else{
                                    id = val.id.toString()
                                }
                                if (val.id.toString() === "-1"){
                                    id = "default"

                                }

                            })


                            SetFormulaOb([id,FormulaOb[1]])
                        }}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    className={'chip_hw'}
                                    label={option.name}
                                    {...getTagProps({ index })}

                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} label="Students"                      className={'students_auto'}
                                       id={"students_auto_" + props.id.toString()} placeholder="Students" />
                        )}
                    />
                    <div>
                        <TextField sx={{width:'100%',marginTop:'3%'}}
                                   id={'formula_input_'+props.id}
                                   className={'formula_input'}
                                   onClick={handleCursorChange}
                                   onKeyUp={handleCursorChange}
                                   onChange={handleChange} label="Formula" placeholder="Formula" />
                    </div>
                    {props.task.computational && <div style={{marginTop: '3%'}}>
                        <h4>Homeworks</h4>
                        <div>Click on the homework to add it as part of the expression</div>
                        <div style={{margin:'2% 0'}}>{TasksList.map(task => <span style={{margin: '2%'}}>
                        <Chip id={task.id}
                              onClick={(e)=>handleInsertValue(e,task.name)}
                              label={task.name}
                              variant={'outlined'}
                        />
                        </span>)}</div>
                        {Error && <div style={{padding:'2%'}}><b>{Error}</b></div>}

                    </div>}



                </div>
            <hr/>
        </div>

    );

}

export default Formula;
