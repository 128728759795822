import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
var FileDownload = require('js-file-download');

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import './task.css'
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from "@mui/material/MenuItem";
import {
    Card, CardActions, CardContent, Chip,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Divider, ListItemIcon, Menu, Snackbar, ThemeProvider
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import axios from "axios";
import Typography from "@mui/material/Typography";
import {Col, Row} from "react-bootstrap";
import TaskModal from "./TaskModal";
import ModalFormula from "./ModalFormula";

function TaskProfessor(props) {


    const { tasks,curCourse } = useContext(AppContext);
    const [Error,SetError] = useState(false)
    const [AddedTask,SetAddedTask] = useState(false)
    const [SuccMessage,SetSuccMessage] = useState(false)
    const [Attachment,SetAttachment] = useState(false)
    const [AttachmentName,SetAttachmentName] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [CurCourse,SetCurCourse] = curCourse
    const [Publish,SetPublish] = useState(false)
    const open = Boolean(anchorEl);
    const [Tasks,SetTasks] = tasks
    const [Downloading,SetDownloading] = useState(false)
    const [Remove, SetRemove] = useState(false)
    const [CreateTask,SetCreateTask] = useState(false)
    const [openDelete,SetOpenDelete] = useState(false)
    const [openSubmit,SetOpenSubmit] = useState(false)
    const [OpenFormula,SetOpenFormula] = useState(false)
    function removetask(e){
        e.preventDefault()
        axios.delete('/task',{data:{task:props.task.id}})
            .then(response=>{
                if (response.data['error']){
                    SetError('The task cannot be removed')
                }
                SetRemove(false)
                var tasks = Tasks.map(t=>t)
                var filter_tasks = props.filteredtasks.map(t=>t)
                tasks = tasks.filter(t=>t.id !== props.task.id)
                filter_tasks = filter_tasks.filter(t=>t.id !== props.task.id)
                SetTasks(tasks)
                props.setfiltered(tasks)
            })

    }

    useEffect(()=>{
        SetError(false)
    },[Attachment])

    useEffect(()=>{
        if(props.task.attachment){
            SetAttachmentName(props.task.attachment)
        }
    },[props.task])
    function submitHomework(e){
        e.preventDefault()
        var task_id = props.task.id
        var input = document.getElementById('hw_upload_'+task_id);
        // SetInpuLength(input.files.length)
        // var file = []
        // if(input.files[0] !== undefined || input.files[0] !== null) {
        //     file = input.files[0]
        // }
        // console.log('file',file)
        let fd = new FormData();
        if(!Attachment){
            SetError('An error occurred')
        }else {


            fd.set('file', Attachment)
            fd.set('task', task_id)
            fd.set('individual', props.task.individual)
            axios({
                method: "post",
                url: "submit_task",
                data: fd,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(response => {
                    props.task.complete = true
                    props.task.waiting = false
                    SetOpenSubmit(false)
                    SetError("Submitted")
                })
                .catch(error => {
                        SetError('An error occurred saving your submission')
                    }
                )
        }
    }
    function handleCloseSnack(){
        SetError(false)
        SetSuccMessage(false)
    }
    function handleCloseModal(){
        SetOpenDelete(false)
        SetOpenSubmit(false)
    }
    function handleCloseModalRemove(){
        SetRemove(false)
    }
    function handleCloseModaDownload(){
        SetDownloading(false)
    }
    function handleCloseModalPublish(){
        SetPublish(false)
    }


    function downloadSubmission(){
            SetDownloading(true)
            axios.get('/download_all_submissions',{params:{taskid:props.task.id,courseid:CurCourse.id},responseType: 'blob'})
                .then(response => {
                    // Creating the blob file and its url
                    var blob = new Blob([response.data]);
                    let url = window.URL.createObjectURL(blob);


                    // Creating the hyperlink and auto click it to start the download
                    let link = document.createElement('a');
                    link.href = url;
                    link.download = 'tasks.zip'
                    link.click();
                    SetDownloading(false)

                    //window.location.href = response.url;
                });



    }
    function publishAll(){
        axios.post('/publish_results',{taskid:props.task.id}).then(response=>{
            SetSuccMessage('Results published.')
            SetPublish(false)
            }
        ).catch(error=>SetError('An error occurred publishing results.'))
    }
    return (
        <div style={{margin:'2%'}}>
            {CreateTask && <TaskModal open={CreateTask} setopen={SetCreateTask} task={props.task} setnewtask={props.setnewtask} />}
            {OpenFormula && <ModalFormula open={OpenFormula} setopen={SetOpenFormula} task={props.task}  />}

            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <Snackbar
                open={SuccMessage}
                autoHideDuration={6000}
                severity="success"
                onClose={handleCloseSnack}
                message={SuccMessage}
            />
            <Dialog
                open={Publish}
                onClose={handleCloseModalPublish}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to publish the results?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalPublish}>Cancel</Button>
                    <Button onClick={publishAll} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={Downloading}
                onClose={handleCloseModaDownload}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Download homeworks for the task: {props.task.name}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Downloading. Please wait.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={Remove}
                onClose={handleCloseModalRemove}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Remove the task: {props.task.name}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalRemove}>Cancel</Button>
                    <Button onClick={removetask} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelete || openSubmit}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {openDelete && "Delete the current submission?"}
                    {openSubmit && "Upload submission?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You can always upload a new file until the deadline.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    {openDelete && <Button onClick={deleteSubmission} autoFocus>
                        Ok
                    </Button>}
                    {openSubmit && <Button onClick={submitHomework} autoFocus>
                        Ok
                    </Button>}
                </DialogActions>
            </Dialog>
        <Card variant={'outlined'} sx={{padding:'2%'}}>
            <CardContent>
                <Typography component="div"><>
                    {props.task.mandatory && props.task.computational === false  && <><Chip variant={'outlined'}  label="Mandatory" />&nbsp;</>}
                    {props.task.optional && props.task.computational === false  && <><Chip variant={'outlined'}  label="Optional" />&nbsp;</>}
                    {props.task.individual   && <><Chip variant={'outlined'} label="Individual" />&nbsp;</>}
                    {props.task.assessment   && <><Chip variant={'outlined'} label="Assessment" />&nbsp;</>}
                    {props.task.group  && <><Chip  variant={'outlined'}  label="Group" />&nbsp;</>}
                    {props.task.computational && <><Chip variant={'outlined'} label="Computational" />&nbsp;</>}
                    {props.task.open  && <><Chip color={'primary'} label="Open" />&nbsp;</>}
                    {props.task.closed   && <><Chip color={'error'} label="Closed" />&nbsp;</>}
                    <hr/>
                </>

                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Task is open from: </b><span>{props.task.taskfromdeadline}</span>&nbsp; <b> to: </b>&nbsp; <span>{props.task.taskdeadline}</span>
                </Typography>
                <Typography variant="h5" component="div">
                    {props.task.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Range: {props.task.scorerange}
                </Typography>
                <Typography variant="body2">
                    {props.task.description}
                </Typography><br/>
                {props.task.computational === false && <Typography variant="body2">
                    Submitted: {props.task.submitted}
                </Typography>}
                {props.task.computational === false && <Typography variant="body2">
                    Evaluated: {props.task.evaluated}
                </Typography>}
                {props.task.computational === false && <Typography variant="body2">
                    Published: {props.task.published}
                </Typography>}
                {props.task.individual && <Typography variant="body2">
                    Total enrolled students: {props.task.total_student}
                </Typography>}
                {props.task.individual === false && <Typography variant="body2">
                    Total enrolled groups: {props.task.total_group}
                </Typography>}
                <br/><br/><hr/>
                {props.task.evalfnjson && Object.keys(props.task.evalfnjson).length >0 && <Typography>
                    <h5>Task Evaluation Functions</h5>
                    {Object.keys(props.task.evalfnjson).map((key,index)=>
                        <div>
                            <b>{key === 'default' ? 'default: ' : " Formula assigned to "+key.split('_').length+" students: "}</b>
                            <span>{props.task.evalfnjson[key]}</span>
                        </div>
                    )}
                </Typography>}<br/><br/><hr/>
                <Typography>

                    <div>
                        <div>
                           <div style={{float:'right',display:'flex'}}>

                               {(props.task.computational || props.task.assessment) && <div style={{display: 'inline-block', marginLeft: '2%'}}>
                                   <Button size={'small'} variant='outlined' color='secondary'
                                           onClick={() => SetOpenFormula(prev => !prev)}>Formula</Button>
                               </div>}
                               {props.task.computational === false && <div style={{display: 'inline-block', marginLeft: '2%'}}>
                                    <Button size={'small'} variant='outlined' color='success'
                                            onClick={downloadSubmission}>Download</Button>
                                </div>}
                                <div style={{display:'inline-block',marginLeft:'2%'}}>
                                 <Button size={'small'} href={window.location.origin+"/evaluate-task/"+props.task.id} variant='outlined' color='success'>Evaluate</Button>
                                </div>
                               <div style={{display:'inline-block',marginLeft:'2%'}}>
                                    <Button  size={'small'} variant = 'outlined' color='success' onClick={()=>SetPublish(prev=>!prev)}>Publish</Button>
                                </div>

                            </div>

                        </div>
                        <div style={{float:'left',display:'flex'}}>
                            {props.task.submitted === 0 &&  <div style={{display: 'inline-block', marginLeft: '2%'}}>
                                <Button size={'small'} variant='outlined' onClick={()=>{SetRemove(prev=>!prev)}} color='error'>Remove</Button>
                            </div>}
                            <div style={{display: 'inline-block', marginLeft: '2%'}}>
                                <Button size={'small'} onClick={(e)=>{
                                    e.preventDefault()
                                    SetCreateTask(true)
                                }} variant='outlined' color='error'>Modify</Button>
                            </div>

                        </div>
                    </div>
                    </Typography>
            </CardContent>
        </Card>
        </div>
    );

}


export default TaskProfessor;
