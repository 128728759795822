// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuitem{
    width:40px;
    display: inline-block;
}
.sortingdiv{
    text-align: end;
    margin:2%;
}
.taskcontainer{
    margin:2%;
    text-align: center;
}
.chip_hw:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/tasks/task.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,qBAAqB;AACzB;AACA;IACI,eAAe;IACf,SAAS;AACb;AACA;IACI,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB","sourcesContent":[".menuitem{\n    width:40px;\n    display: inline-block;\n}\n.sortingdiv{\n    text-align: end;\n    margin:2%;\n}\n.taskcontainer{\n    margin:2%;\n    text-align: center;\n}\n.chip_hw:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
