import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import {Container,Row,Col} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';

import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from "@mui/material/MenuItem";
import {
    CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Divider, ListItemIcon, Menu, Snackbar, ThemeProvider
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import axios from "axios";
import {Redirect} from "react-router-dom";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";


function Information() {


    const { academicYears,group,email,studentCourses,courseslist,studentDegree,degrees,curCourse } = useContext(AppContext);

    const [StudentCourses,SetStudentCourses] = studentCourses
    const [AcademicYears,SetAcademicYears] = academicYears
    const [Email,SetEmail] = email
    const [LateDeadline,SetLateDeadline] = useState(false)
    const [NearDeadline,SetNearDeadline] = useState(false)
    const [OpenModal,SetOpenModal] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [Motivation,SetMotivation] = useState("")
    const [Mandatory,SetMandatory] = useState(false)
    const [Optional,SetOptional] = useState(false)
    const [Group,SetGroup] = useState(false)
    const [Individual,SetIndividual] = useState(false)
    const [Computational,SetComputational] = useState(false)
    const [Open,SetOpen] = useState(false)
    const [Closed,SetClosed] = useState(false)
    const [Redir,SetRedir] = useState(false)
    const [Error,SetError] = useState(false)
    const [InfoObject,SetInfoObject] = useState(false)

    useEffect(()=>{
        axios.get('/information',{params:{course:CurCourse.id}})
            .then(response=>SetInfoObject(response.data))
            .catch(error=>console.log(error))
    },[])

    function unsubscribe() {
        console.log('course', CurCourse.id)
        if(Motivation.trim().length === 0){
            SetError('The motivation must contain at least 10 characters.')
        }else{
            axios.post('/unsubscribe', {course: CurCourse.id,motivation:Motivation})
                .then(response => {
                    if (response.data['error']){
                        SetError(response.data['error'])
                    }else{
                        SetRedir(true)

                    }
                })
                .catch(error => {
                    console.log(error);
                    SetError('An error occurred')}
                )
        }




    }
    function handleCloseSnack(){
        SetError(false)
    }
    function handleClose(){
        SetOpenModal(false)
        SetError(false)
    }

    return (
        <div>
            {Redir === true && <Redirect to='/my-courses'/>}

            {/*<HeaderBar />*/}
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <Dialog
                open={OpenModal}
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Withdraw from {CurCourse.name}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action will remove you from the course and all the related activities. Please provide a reason if you want to unsubscribe from the course.<br/><br/><br/>

                        <TextField
                            id="declinemotivation"
                            value={Motivation}
                            sx={{width:"100%"}}
                            onChange={(e)=>SetMotivation(e.target.value)}
                            label="Motivation"
                            multiline
                            maxRows={4}
                        />

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={unsubscribe}  disabled={Motivation === ""} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {InfoObject ? <div className={'sortingdiv'}>
                <Row><Col md={3}></Col>  <Col md={2}><b>Status:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.studentstatus}</Col><Col md={3}></Col> </Row>
                <Row><Col md={3}></Col>  <Col md={2}><b>Professors:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.professors}</Col><Col md={3}></Col> </Row>
                <Row> <Col md={3}></Col> <Col md={2}><b>Enrollment deadline:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.enrollment_deadline}</Col><Col md={3}></Col> </Row>
                <Row> <Col md={3}></Col> <Col md={2}><b>Group creation and update deadline:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.group_deadline}</Col><Col md={3}></Col> </Row>
                {/*<Row> <Col md={2}><b>Students evaluation deadline:</b></Col><Col md={10}>InfoObject.studentevaluation</Col></Row>*/}
                <hr/>
                {/*<Row> <Col md={2}><b>Date of enrollment:</b></Col><Col md={10}>InfoObject.enrollment_time</Col></Row>*/}
                <Row> <Col md={3}></Col> <Col md={2}><b>Group:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.group}</Col><Col md={3}></Col> </Row>
                <Row> <Col md={3}></Col> <Col md={2}><b>Date of group creation:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.groupcreation}</Col><Col md={3}></Col> </Row>
                <Row><Col md={3}></Col>  <Col md={2}><b>Date of group modification:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.groupupdate}</Col><Col md={3}></Col> </Row>
                <Row><Col md={3}></Col>  <Col md={2}><b>Total tasks:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.taskstotal}</Col><Col md={3}></Col> </Row>
                <Row><Col md={3}></Col>  <Col md={2}><b>Submitted tasks:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.submittedtasks}</Col><Col md={3}></Col> </Row>
                {/*<Row> <Col md={2}><b>Tasks awaiting for submission:</b></Col><Col md={10}>InfoObject.awaitingtasks</Col></Row>*/}
                <Row><Col md={3}></Col> <Col md={2}><b>Total score:</b></Col><Col style={{textAlign:'left'}} md={4}>{InfoObject.score}</Col><Col md={3}></Col> </Row>
                <hr/>
                <Button variant={'contained'} color={'error'} onClick={()=>SetOpenModal(true)}>Unsubscribe from this course</Button>


            </div> : <div><CircularProgress /></div>}
        </div>

    );
}


export default Information;
