import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import './headerbar.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import {styled} from "@mui/material/styles";


function ProfHeaderBar(props) {


    const { academicYears,email,curCourse,username } = useContext(AppContext);
    const [Username,SetUsername] = username
    const [CurCourse,SetCurCourse] = curCourse



    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const OptionsButton = styled(Button)(({ theme }) => ({
            color: '#941010',
            margin: '10px 10px',
            // float: 'right',
            border: '1px solid #941010',
            '&:hover': {
                backgroundColor: 'rgba(148,16,16,0.27)',
                margin: '10px 10px',
                // float: 'right',
                color: '#941010',

            },
    }))



    return (
        <div className={'headerbar'}>

            <div className={'secondheader'}>

                {CurCourse && <Box sx={{alignItems: 'center', height: 'inherit', textAlign: 'center', float: 'center'}}>

                    <OptionsButton
                        href={window.location.origin + "/task/"+CurCourse.id}>Tasks</OptionsButton>


                    <OptionsButton
                        href={window.location.origin + "/group/" + CurCourse.id}>Groups</OptionsButton>


                    <OptionsButton
                        href={window.location.origin + "/students/"+ CurCourse.id}>Students</OptionsButton>


                </Box>}




            </div>

        </div>
    );
}


export default ProfHeaderBar;
