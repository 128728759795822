import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import './course.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'
import '../../App.css';
// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect} from "react-router-dom";
import {
    Autocomplete,
    CircularProgress,
    Collapse, createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, ThemeProvider
} from "@mui/material";
import SearchCourseBar from "./SearchCourseBar";
import HeaderBar from "../utils/headerbar";


function CoursesList() {


    const { academicYears,group,email,studentCourses,courseslist,studentDegree,degrees,curCourse } = useContext(AppContext);

    const [StudentCourses,SetStudentCourses] = studentCourses
    const [AcademicYears,SetAcademicYears] = academicYears
    const [Email,SetEmail] = email
    const [CourseList,SetCourseList] = courseslist
    const [OpenSearch,SetOpenSearch] = useState(false)
    const [CourseToAdd,SetCourseToAdd] = useState(false)
    const [StudentDegree,SetStudentDegree] = studentDegree
    const [Degrees,SetDegrees] = degrees
    const [Group,SetGroup] = group
    const [OpenDialog,SetOpenDialog] = useState(false)
    const [CFU,SetCFU] = useState(false)
    const [Error,SetError] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const styleObj = {
        '&:disabled': {
            color: 'darkgreen',     // Change the text color for disabled state
            border: '1px solid darkgreen',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };
    const styleObj1 = {
        '&:disabled': {
            color: 'darkred',     // Change the text color for disabled state
            border: '1px solid darkred',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };

    useEffect(()=>{
        if(CourseToAdd){
            if(CourseToAdd.cfus.length === 1){
                SetCFU(CourseToAdd.cfus[0])
            }else{
                SetCFU(false)
            }
        }
    },[CourseToAdd])


    const theme = createTheme({
        root: {

            '&:disabled': {
                background: 'gray', // Change the background color for disabled state
                color: 'darkgreen',     // Change the text color for disabled state
                border: 'darkgreen',     // Change the text color for disabled state
                boxShadow: 'none',  // Remove the box shadow for disabled state
            },
        },
    })

    useEffect(()=>{
        // SetCurCourse(false)
        // SetGroup(false)
        axios.get("courses",{params:{type:'student'}}).then(response=>{
            SetStudentCourses(response.data['courses'])
        }).catch(error=>console.log(error))

        axios.get("courses").then(response=>{
            console.log('CONSOLE LIST ',response.data['courses'])
            SetCourseList(response.data['courses'])
        }).catch(error=>console.log(error))

        axios.get("degree").then(response=>{
            SetDegrees(response.data['degrees'])
        }).catch(error=>console.log(error))

        axios.get("academic_years").then(response=>{
            SetAcademicYears(response.data['academicyears'])
        }).catch(error=>console.log(error))


    },[])

    function normalize(string){
        string = string.toLowerCase().replaceAll(' ', '').replaceAll('null', '')
        return (string !== '' && string !== null && string && string !== undefined)
    }


    const handleAddCourse = () => {
        var cfu = 0
        if (CFU === false && CourseToAdd && CourseToAdd.cfus.length === 1){
            cfu = (CourseToAdd.cfus[0])
        }else if(CFU){
            cfu = CFU
        }
        var searchbars = document.getElementsByName("searchbar")
        //searchbars.map(x=>x.value = "")
        var notes = normalize(document.getElementById('note').value) ? document.getElementById('note').value : ''
        axios.post("courses",{course:CourseToAdd.id,degree:StudentDegree.id,cfu:cfu,note:notes})
            .then(response=>{
                var courses_to_add = []
                CourseList.map(course=>{
                    if(course.id === CourseToAdd.id){
                        course.status = 'enrolled'
                        course.cfus = [cfu]
                        courses_to_add.push(course)
                    }
                })
                var courses = StudentCourses.map(x=>x)
                courses_to_add = [...courses,...courses_to_add]
                SetStudentCourses(courses_to_add)
                SetCourseToAdd(false);
                SetCFU(false)
                SetOpenDialog(false)
                SetOpenSearch(false)

            }).catch(error=> {
            console.log(error)
            SetOpenDialog(false)
            SetError("An error occurred during subscription")
        })
    };
    const handleClose = () => {
        SetOpenDialog(false)


    };
    useEffect(()=>{
        SetError(false)
    },[StudentDegree,CourseToAdd])

    function subscribe(e){
        e.preventDefault()
        if(CourseToAdd !== false && StudentDegree !== false && ((CourseToAdd.cfus.length === 1) ||
            (CFU !== false && CourseToAdd.cfus.length > 0))){
            SetOpenDialog(true)
        }
        else{
            SetOpenDialog(false)
        }

    }

    return (
        <div>
            <HeaderBar />

            <Dialog
                open={OpenDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {CourseToAdd.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are subscribing to: {CourseToAdd.name}<br/>
                        If you need to add some information exclusively related to this course, please, write below.
                        <div>
                            <TextField
                                id="note"
                                label="Notes to the professor(s)"
                                multiline
                                fullWidth
                                maxRows={4}
                            />
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddCourse} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                {(Degrees && CourseList && StudentCourses && AcademicYears) ?
                    <div>
                        <div >
                            <Container fluid>

                                <div style={{textAlign:'center',margin:'2%'}}>
                                    <h1>My Courses</h1>

                                    <div>Here you can subscribe to new courses and access those you are currently enrolled into</div>
                                    <div className={'addcourse'}>
                                        <Button variant={'outlined'} onClick={()=>SetOpenSearch(prev=>!prev)} color={'error'} size={'large'}>Add a new course</Button>
                                        <Collapse in={OpenSearch}>
                                            <div style={{marginTop:'2%'}}>
                                                You can choose courses of the <b>current</b> academic year.
                                                <Row>
                                                    <Col md={3}></Col>
                                                    <Col md={6}>

                                                <SearchCourseBar entire_list={Degrees} filtered_list={[StudentDegree]} selection={[StudentDegree,SetStudentDegree]} label={"Select your degree"} />
                                                <SearchCourseBar  entire_list={CourseList} filtered_list={StudentCourses} selection={[CourseToAdd,SetCourseToAdd]} label={"Select a course"}/>
                                                {CourseToAdd && CourseToAdd.cfus.length > 1 &&
                                                    <>
                                                        <Autocomplete

                                                            id="combo-box-demo"
                                                            options={CourseToAdd.cfus}

                                                            sx={{ width: '100%', margin:'3%' }}
                                                            onChange={(e,newVal)=>SetCFU(newVal)}

                                                            renderInput={(params) => <TextField {...params} label="Select the CFU for the course" />}
                                                        />


                                                    </>


                                                }</Col>
                                                    <Col md={3}></Col>
                                                </Row>
                                                <div style={{textAlign:'center'}}>

                                                    {Error && <div style={{margin:'3%'}}><Alert severity="error">An error occurred</Alert>
                                                    </div>
                                                    }
                                                    {CourseToAdd.enrolldeadline === true && <div className={'enrollerror'}>The enrollment for this course is closed</div>}
                                                    <Button disabled={!(CourseToAdd !== false && StudentDegree !== false && CourseToAdd.enrolldeadline === false && CFU !== false)} variant={'contained'} disableElevation color={'success'} size={'large'} onClick={subscribe}>Subscribe</Button>

                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>

                                </div>

                        {StudentCourses.length === 0 && <div style={{margin:'0 3%'}}>
                            <h3>You have not subscribed to any course yet</h3>
                        </div>}
                        {AcademicYears && StudentCourses && StudentCourses.length > 0 && AcademicYears.map(ay=>
                            <div className={'year'}>

                                <h2>{ay.name}</h2><hr/>
                                {StudentCourses.map(course=>
                                    <>
                                        {course.academic_year === ay.name && course.is_alias === false && <div className={'course'}>
                                            <div>

                                                {course.status === 'enrolled' ? <a href={window.location.origin+"/courses/"+course.id}>{course.name}</a> : <div>{course.name}</div>}



                                            </div>
                                            {course.alias.length > 0 && <div>
                                                Alias:
                                                <div>
                                                    <ul>
                                                        {course.alias.map(c=>
                                                            <li>
                                                                {<>{c.name.split("(")}</>}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>

                                            </div>}
                                            <div>CFU: <b>{course.cfus.join(', ')}</b></div>
                                            <div>Status: <b>{course.status}</b></div>
                                            <div>Group update and creation: {course.groupdeadline ?
                                                <Button variant="outlined" color="error" sx={styleObj1}disabled size={'small'}>
                                                    Closed
                                                </Button> :
                                                <Button variant="outlined" color="success" sx={styleObj} size={'small'} disabled>
                                                    open
                                                </Button>
                                            }</div>
                                            <div>You can join and update your group until: {course.groupdeadline_txt}</div>




                                        </div>}
                                    </>
                                )}
                            </div>
                        )}


                            </Container>
                        </div>


                    </div> : <div className={'circularbar'}><CircularProgress /></div>
                }
            </div>
        </div>

    );
}


export default CoursesList;
