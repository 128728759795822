import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
const theme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'unipd' },
                    style: {
                        color: '#ffffff',
                        backgroundColor: 'transparent',
                        margin:'5px',
                        borderRadius: '4px',
                        padding: '10px 20px',
                        border: '1px solid #ffffff',
                        transition: 'background-color 0.3s', // Add transition for smooth effect
                        '&:hover': {
                            // color: '#9b0014',
                            color: '#ffffff',
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            borderRadius: '4px',
                            textDecoration:"none",
                            padding: '10px 20px',
                            margin:'5px',
                            border: '1px solid #ffffff',
                        },
                        '&:focus': {
                            color: '#ffffff',
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            borderRadius: '4px',
                            margin:'5px',
                            textDecoration: "none",

                            padding: '10px 20px',
                            border: '1px solid #ffffff',
                        },
                    },

                },
            ],
        },
    },
});

// import { Button, makeStyles } from '@material-ui/core';
// const useStyles = makeStyles({
//     button: {
//         color: '#ffffff',
//         backgroundColor: 'transparent',
//         margin:'5px',
//         borderRadius: '4px',
//         padding: '10px 20px',
//         border: '1px solid #ffffff',
//         transition: 'background-color 0.3s', // Add transition for smooth effect
//         '&:hover': {
//             // color: '#9b0014',
//             color: '#ffffff',
//             backgroundColor: 'rgba(255,255,255,0.2)',
//             borderRadius: '4px',
//             padding: '10px 20px',
//             margin:'5px',
//
//             border: '1px solid #ffffff',
//         },
//         '&:focus': {
//             color: '#ffffff',
//             backgroundColor: 'rgba(255,255,255,0.2)',
//             borderRadius: '4px',
//             margin:'5px',
//
//             padding: '10px 20px',
//             border: '1px solid #ffffff',
//         },
//         // ... add more styles as needed
//     },
// });
const ButtonMaterial = (props) => {
    // const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>

        <Button sx={{textDecoration:"none"}} variant="unipd" {...props}>
            {props.children}
        </Button>
        </ThemeProvider>
    );
};
export default ButtonMaterial