import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";
import {Container,Row,Col} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';

import Button from '@mui/material/Button';
import './task.css'
import '../../App.css'
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from "@mui/material/MenuItem";
import {
    CircularProgress, Collapse,
    DialogTitle, Divider, ListItemIcon, Menu, ThemeProvider
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import axios from "axios";
import Task from "./Task";
import Summary from "../tables/Summary";


function TasksList() {


    const { academicYears,group,email,studentCourses,courseslist,studentDegree,degrees,curCourse } = useContext(AppContext);

    const [StudentCourses,SetStudentCourses] = studentCourses
    const [AcademicYears,SetAcademicYears] = academicYears
    const [Email,SetEmail] = email
    const [LateDeadline,SetLateDeadline] = useState(false)
    const [NearDeadline,SetNearDeadline] = useState(false)
    const [Submission,SetSubmission] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [OpenOverview,SetOpenOverview] = useState(false)
    const [Mandatory,SetMandatory] = useState(false)
    const [Optional,SetOptional] = useState(false)
    const [Group,SetGroup] = useState(false)
    const [Individual,SetIndividual] = useState(false)
    const [Computational,SetComputational] = useState(false)
    const [Open,SetOpen] = useState(false)
    const [Closed,SetClosed] = useState(false)
    const [Complete,SetComplete] = useState(false)
    const [Waiting,SetWaiting] = useState(false)
    const [Submitted,SetSubmitted] = useState(false)
    const [NotSubmitted,SetNotSubmitted] = useState(false)
    const [All,SetAll] = useState(false)

    const [Tasks,SetTasks] = useState(false)
    const [FilteredTasks,SetFilteredTasks] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElTask, setAnchorElTask] = useState(null);
    const open = Boolean(anchorEl);
    const openTask = Boolean(anchorElTask);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickTask = (event) => {
        setAnchorElTask(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseTask = () => {
        setAnchorElTask(null);
        SetAll(true)
    };
    function custom_sort_sub(a, b) {
        var data = new Date(a.submissiontime).getTime() - new Date(b.submissiontime).getTime();
        return data
    }
    function custom_sort_near(a, b) {
        var data = new Date(a.taskdeadline).getTime() - new Date(b.taskdeadline).getTime();
        return data
    }
    function custom_sort_late(a, b) {
        var data = - new Date(a.taskdeadline).getTime() + new Date(b.taskdeadline).getTime();
        return data
    }
    useEffect(()=>{
        axios.get('/task',{params:{course:CurCourse.id}})
            .then(response=>{SetTasks(response.data['tasks']);SetFilteredTasks(response.data['tasks'])})
    },[])


    useEffect(()=>{
        if(FilteredTasks){
            var filtered = FilteredTasks.map(x=>x)
            if(Submission){
                FilteredTasks.forEach(item => {
                    item.submissiontime_date = new Date(item.submissiontime);
                });
                SetLateDeadline(false)
                SetNearDeadline(false)
                SetSubmission(true)
                filtered.sort(custom_sort_sub); // discendente
                SetFilteredTasks(filtered)

            }else if(LateDeadline){
                filtered.forEach(item => {
                    item.taskdeadline_date = new Date(item.taskdeadline);
                });

                SetSubmission(false)
                SetNearDeadline(false)
                SetLateDeadline(true)
                filtered = filtered.sort(custom_sort_late); // discendente
                SetFilteredTasks(filtered)


            }else if(NearDeadline) {
                filtered.forEach(item => {
                    item.taskdeadline_date = new Date(item.taskdeadline);
                });

                SetSubmission(false)
                SetLateDeadline(false)
                SetNearDeadline(true)
                filtered = filtered.sort(custom_sort_near); // discendente
                SetFilteredTasks(filtered)
            }
        }
        setAnchorElTask(null);
    },[Submission,NearDeadline,LateDeadline])

    useEffect(()=>{
        if(Tasks) {
            var tasks = Tasks.map(x => x)

            var filtered_tasks = []
            if (All) {
                SetFilteredTasks(tasks)
            } else {

                if (Mandatory) {
                    var mandatory_tasks = tasks.filter(x => x.mandatory === true)
                    filtered_tasks = filtered_tasks.concat(mandatory_tasks)
                }
                else if (Optional) {
                    var optional_tasks = tasks.filter(x => x.optional === true)
                    filtered_tasks = filtered_tasks.concat(optional_tasks)

                }
                else if (Group) {
                    var group_tasks = tasks.filter(x => x.group === true)
                    filtered_tasks = filtered_tasks.concat(group_tasks)

                }
                else if (Individual) {
                    var individual_tasks = tasks.filter(x => x.individual === true)
                    filtered_tasks = filtered_tasks.concat(individual_tasks)

                }
                else if (Open) {
                    var open_tasks = tasks.filter(x => x.open === true)
                    filtered_tasks = filtered_tasks.concat(open_tasks)

                }
                else if (Closed) {
                    var closed_tasks = tasks.filter(x => x.closed === true)
                    filtered_tasks = filtered_tasks.concat(closed_tasks)

                }
                else if (Computational) {
                    var comput_tasks = tasks.filter(x => x.computational === true)
                    filtered_tasks = filtered_tasks.concat(comput_tasks)

                }
                else if (Waiting) {
                    var waiting_tasks = tasks.filter(x => x.waiting === true)
                    filtered_tasks = filtered_tasks.concat(waiting_tasks)

                }
                // if (Complete) {
                //     var complete_tasks = tasks.filter(x => x.complete === true)
                //     filtered_tasks = filtered_tasks.concat(complete_tasks)
                //
                //
                // }
                // if (Submitted) {
                //     var submitted_tasks = tasks.filter(x => x.submitted === true)
                //     filtered_tasks = filtered_tasks.concat(submitted_tasks)
                //
                //
                // }
                // if (NotSubmitted) {
                //     var nosubmit_tasks = tasks.filter(x => x.not_submitted === true)
                //     filtered_tasks = filtered_tasks.concat(nosubmit_tasks)
                // }

                var final_list = []

                filtered_tasks.map(x=>{
                    if(final_list.filter(y=>y.id === x.id).length === 0){
                        final_list.push(x)
                    }
                })
                SetFilteredTasks(final_list)
                // if(final_list.length === 0){
                //     SetFilteredTasks(tasks)
                //
                //
                // }else{
                //     SetFilteredTasks(final_list)
                //
                // }
                setAnchorEl(null);

            }
        }

    },[Mandatory,Optional,Individual,Group,Open,Closed,Waiting,Complete,Submitted,NotSubmitted,Computational])

    useEffect(()=>{
        if(All){
            SetMandatory(false)
            SetOptional(false)
            SetGroup(false)
            SetComputational(false)
            SetIndividual(false)
            SetOpen(false)
            SetClosed(false)
            SetWaiting(false)
            SetComplete(false)
            SetSubmitted(false)
            SetNotSubmitted(false)
            setAnchorEl(null);
        }else{
            setAnchorEl(null);
        }
    },[All])


    return (
        <div>
            {/*<HeaderBar />*/}

            <div className={'sortingdiv'}>
                <Button
                    id="open-overview-button"
                    variant={'contained'}
                    size={'large'}
                    onClick={()=>SetOpenOverview(prev=>!prev)}
                >
                    Scores overview
                </Button>
                <Button
                    id="sort-task-button"
                    aria-controls={openTask ? 'sort-task-button' : undefined}
                    aria-haspopup="true"
                    size={'large'}
                    aria-expanded={openTask ? 'true' : undefined}
                    onClick={handleClickTask}
                >
                    Sort tasks
                </Button>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorElTask}
                    open={openTask}
                    onClose={handleCloseTask}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={()=> {
                        SetNearDeadline(prev => !prev);
                        SetSubmission(false)
                        SetLateDeadline(false)
                    }}>
                        <div className={'menuitem'}>
                            {NearDeadline && <ListItemIcon>
                                <CheckIcon fontSize="small"/>
                            </ListItemIcon>}
                        </div>

                        Ascending
                    </MenuItem>
                    <MenuItem onClick={()=>{
                        SetLateDeadline(prev => !prev);
                        SetSubmission(false)
                        SetNearDeadline(false)
                    }}>
                        <div className={'menuitem'}>
                            {LateDeadline && <ListItemIcon>
                                <CheckIcon fontSize="small"/>
                            </ListItemIcon>}
                        </div>
                        Descending
                    </MenuItem>
                    {/*<MenuItem onClick={()=>{*/}
                    {/*    SetSubmission(prev => !prev);*/}
                    {/*    SetLateDeadline(false)*/}
                    {/*    SetNearDeadline(false)*/}
                    {/*}}>*/}
                    {/*    <div className={'menuitem'}>*/}
                    {/*        {Submission && <ListItemIcon>*/}
                    {/*            <CheckIcon fontSize="small"/>*/}
                    {/*        </ListItemIcon>}*/}
                    {/*    </div>*/}
                    {/*    Submission*/}
                    {/*</MenuItem>*/}




                </Menu>
                    <Button
                        id="filter-task-button"
                        aria-controls={open ? 'filter-task-button' : undefined}
                        aria-haspopup="true"
                        size={'large'}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        Filter tasks
                    </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={()=>SetMandatory(prev=>!prev)}>
                            <div className={'menuitem'}>
                                {/*{Mandatory && <ListItemIcon>*/}
                                {/*    <CheckIcon fontSize="small"/>*/}
                                {/*</ListItemIcon>}*/}
                            </div>

                            Mandatory
                        </MenuItem>
                        <MenuItem onClick={()=>SetOptional(prev=>!prev)}>
                            <div className={'menuitem'}>
                                {/*{Optional && <ListItemIcon>*/}
                                {/*    <CheckIcon fontSize="small"/>*/}
                                {/*</ListItemIcon>}*/}
                            </div>

                            Optional
                        </MenuItem>
                        <MenuItem onClick={()=>SetIndividual(prev=>!prev)}>
                            <div className={'menuitem'}>
                                {/*{Individual && <ListItemIcon>*/}
                                {/*    <CheckIcon fontSize="small"/>*/}
                                {/*</ListItemIcon>}*/}
                            </div>

                            Individual
                        </MenuItem>
                        <MenuItem onClick={()=>SetGroup(prev=>!prev)}>
                            <div className={'menuitem'}>
                                {/*{Group && <ListItemIcon>*/}
                                {/*    <CheckIcon fontSize="small"/>*/}
                                {/*</ListItemIcon>}*/}
                            </div>

                            Group
                        </MenuItem>
                        <MenuItem onClick={()=>SetComputational(prev=>!prev)}>
                            <div className={'menuitem'}>
                                {/*{Computational && <ListItemIcon>*/}
                                {/*    <CheckIcon fontSize="small"/>*/}
                                {/*</ListItemIcon>}*/}
                            </div>

                            Computational
                        </MenuItem>
                        <MenuItem onClick={()=>SetOpen(prev=>!prev)}>
                            <div className={'menuitem'}>
                                {/*{Open && <ListItemIcon>*/}
                                {/*    <CheckIcon fontSize="small"/>*/}
                                {/*</ListItemIcon>}*/}
                            </div>

                            Open
                        </MenuItem>
                        <MenuItem onClick={()=>SetClosed(prev=>!prev)}>
                            <div className={'menuitem'}>
                                {/*{Closed && <ListItemIcon>*/}
                                {/*    <CheckIcon fontSize="small"/>*/}
                                {/*</ListItemIcon>}*/}
                            </div>

                            Closed
                        </MenuItem>
                        {/*<MenuItem onClick={()=>SetComplete(prev=>!prev)}>*/}
                        {/*    <div className={'menuitem'}>*/}
                        {/*        {Complete && <ListItemIcon>*/}
                        {/*            <CheckIcon fontSize="small"/>*/}
                        {/*        </ListItemIcon>}*/}
                        {/*    </div>*/}

                        {/*    Complete*/}
                        {/*</MenuItem>*/}
                        {/*<MenuItem onClick={()=>SetWaiting(prev=>!prev)}>*/}
                        {/*    <div className={'menuitem'}>*/}
                        {/*        {Waiting && <ListItemIcon>*/}
                        {/*            <CheckIcon fontSize="small"/>*/}
                        {/*        </ListItemIcon>}*/}
                        {/*    </div>*/}

                        {/*    Waiting*/}
                        {/*</MenuItem>*/}
                        {/*<MenuItem onClick={()=>SetSubmitted(prev=>!prev)}>*/}
                        {/*    <div className={'menuitem'}>*/}
                        {/*        {Submitted && <ListItemIcon>*/}
                        {/*            <CheckIcon fontSize="small"/>*/}
                        {/*        </ListItemIcon>}*/}
                        {/*    </div>*/}

                        {/*    Submitted*/}
                        {/*</MenuItem>*/}
                        {/*<MenuItem onClick={()=>SetNotSubmitted(prev=>!prev)}>*/}
                        {/*    <div className={'menuitem'}>*/}
                        {/*        {NotSubmitted && <ListItemIcon>*/}
                        {/*            <CheckIcon fontSize="small"/>*/}
                        {/*        </ListItemIcon>}*/}
                        {/*    </div>*/}

                        {/*    Not Submitted*/}
                        {/*</MenuItem>*/}
                        <Divider/>
                        <MenuItem onClick={()=>{
                            SetAll(prev=>!prev)
                            handleClose()}}>All</MenuItem>
                    </Menu>
                </div>
            {OpenOverview && CurCourse && <div>
                <Summary curcourse={CurCourse} />
            </div>}
            {FilteredTasks ? <div className={'taskcontainer'}>
                <h3>OPEN TASKS FOR SUBMISSION</h3>
                <div style={{marginTop:'2%'}}>

                        {FilteredTasks.filter(x=>x.open === true).map(task=>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={10}>
                                    <Task task={task}/>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                           )}

                </div>

                <hr/>
                <h3>CLOSED TASKS</h3>
                <div style={{marginTop:'2%'}}>

                    {FilteredTasks.filter(x=>x.open !== true).map(task=>       <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Task task={task}/>
                        </Col>
                        <Col md={1}></Col>
                    </Row>)}
               </div>

            </div> : <div className={'circularbar'}><CircularProgress /></div>}
        </div>

    );
}


export default TasksList;
