import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'


import {Redirect, useParams} from "react-router-dom";
import {
    Card, CardActions, CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper, Snackbar
} from "@mui/material";
import axios from "axios";
import Typography from "@mui/material/Typography";



function JoinsRequests() {


    const { academicYears,email,studentCourses,courseslist,studentDegree,degrees,curCourse,group } = useContext(AppContext);


    const { id } = useParams();

    const [Group,SetGroup] = group
    const [Email,SetEmail] = email
    const [Error,SetError] = useState(false)
    const [PersonalPendingRequest,SetPersonalPendingRequests] = useState(false)
    const [Groups,SetGroups] = useState(false)
    useEffect(()=>{
        axios.get('/pending-requests',{params:{type:'personal',courseid:id}}).then(response=>{

            SetPersonalPendingRequests(response.data['pending_requests'])
        })

        axios.get('/group',{params:{courseid:id,type:'all'}}).then(response=> {
            SetGroups(response.data['groups'])
        })

    },[])


    function sendRequest(e,group,member,course){
        e.preventDefault()
        // Qui lo studente loggato fa una richiesta
        axios.post('/pending-requests',{group:group,member:member,course:course})
            .then(response=>{SetPersonalPendingRequests(response.data['pending_requests']);window.scrollTo(0,0)})
            .catch(error=>SetError('An error occurred'))
    }

    function removeRequest(e,group,member,course){
        e.preventDefault()
        // qui sto rimuovendo le personali!!
        axios.delete('/pending-requests',{data:{group:group,member:member,course:course}})
            .then(response=>SetPersonalPendingRequests(response.data['pending_requests']))
            .catch(error=>SetError('An error occurred'))
    }


    function handleCloseSnack(){
        SetError(false)
    }

    return (
        <div>
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            {Group && <Alert severity="error">You already belong to a group</Alert>}
            {(PersonalPendingRequest && Groups) ? <div>
                {PersonalPendingRequest.length >0 && <div style={{padding:'3%'}}>
                    <Alert severity="success">You asked the group: {Groups.filter(x=>PersonalPendingRequest.indexOf(x.id) !== -1)[0].name} to join. <br/>
                    In order to ask to another group you have to remove your the current request.
                    </Alert>

                </div>}
                <div style={{textAlign:'left'}}>
                    <b>{Groups.filter(x=>x.members.length > 0).length} groups found</b>

                    <hr/>
                    {Groups.filter(x=>PersonalPendingRequest.indexOf(x.id) !== -1).map(group=><div style={{padding:'0 5%'}}>
                        <Card sx={{ width: '100%' ,margin:'3%',padding:'0 3%',textAlgn:'left'}}>
                            <CardContent>

                                <Typography variant="h5" component="div">
                                    {group.name}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {group.members.length} members
                                </Typography>
                                <Typography variant="body2">
                                    {group.description}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{float:'right'}}>
                                <Button variant='contained' size="small" color = 'error' onClick={(e)=>removeRequest(e,group.id,Email,id)}>Remove request</Button>
                            </CardActions>
                        </Card>


                    </div>)}<hr/>
                        {Groups.filter(x=>PersonalPendingRequest.indexOf(x.id) === -1 && x.members.length >0).map(group=><div style={{padding:'0 5%'}}>
                            <Card sx={{ width: '100%' ,margin:'3%',padding:'0 3%',textAlgn:'left'}}>
                                <CardContent>

                                    <Typography variant="h5" component="div">
                                        {group.name}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {group.members.length} members
                                    </Typography>
                                    <Typography variant="body2">
                                        {group.description}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{float:'right'}}>
                                    <Button disabled={PersonalPendingRequest.length >0} variant='contained' size="small" onClick={(e)=>sendRequest(e,group.id,Email,id)}>Request to join</Button>
                                </CardActions>
                            </Card>
                            </div>


                        )}



               </div>

            </div>: <div><CircularProgress /></div>}

        </div>
    );
}


export default JoinsRequests;
