import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect, useParams} from "react-router-dom";
import {
    Autocomplete,
    Chip,
    CircularProgress,
    Collapse, createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, FormControlLabel, FormGroup, Paper, Radio, RadioGroup, Snackbar, withStyles

} from "@mui/material";
import '../../App.css';

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Formula from "./Formula";
function ModalFormula(props) {


    const { groupinfo,academicYears,studentCourses,email,degree,info,taskinfo,courseslist,studentDegree,degrees,group,curCourse } = useContext(AppContext);
    const [TaskName,SetTaskName] = useState("")
    const [TaskDesc,SetTaskDesc] = useState("")
    const [TaskDeadline,SetTaskDeadline] = useState(null)
    const [TaskFromDeadline,SetTaskFromDeadline] = useState(null)
    const [TaskOptional,SetTaskOptional] = useState(null)
    const [TaskIndividual,SetTaskIndividual] = useState(null)
    const [TaskComputational,SetTaskComputational] = useState(null)
    const [TaskAssessment,SetTaskAssessment] = useState(null)
    const [AttRequired,SetAttRequired] = useState(null)
    const [MinScore, SetMinScore] = useState(null)
    const [MaxScore, SetMaxScore] = useState(null)
    const [Error,SetError] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [FormulaList,SetFormulaList] = useState([])
    const [NewFormula,SetNewFormula] = useState(false)
    const [Formulas,SetFormulas] = useState([<Formula task={props.task} id={"0"} formula={FormulaList} setformula={SetFormulaList}/>])
    useEffect(()=>{
        if(props.task){
            SetTaskName(props.task.name)
            SetTaskDesc(props.task.description)
            SetTaskDeadline(dayjs(props.task.taskdeadline))
            SetTaskFromDeadline(dayjs(props.task.taskfromdeadline))
            const numbers = props.task.scorerange.match(/\d+/g).map(Number);
            const [min, max] = numbers;
            SetMinScore(min)
            SetMaxScore(max)
            SetTaskOptional(props.task.optional ? "Optional" : "Mandatory")
            SetTaskIndividual(props.task.individual ? "Individual" : "Group")
            SetTaskComputational(props.task.computational ? "Computational" : "HW")
            if (props.task.assessment){
                SetTaskAssessment("Assess")
            }else{
                SetTaskAssessment("NoAssess")
            }
            SetAttRequired(props.task.attach_file ? "Att" : "NoAtt")
        }else{
            SetTaskDeadline(dayjs())
            SetTaskFromDeadline(dayjs())
        }
    },[])




    function submitFormulas(){
        var formulas = {};
        var isValid = true;
        if (FormulaList.length === 0){
            isValid = false;
        }
        FormulaList.map(formula=>{
            if(formula[0].trim() === false || formula[0].trim() === undefined || formula[0].trim() === "" || formula[1].trim() === null || formula[1].trim() === undefined || formula[1].trim() === ""){
                isValid = false;
            }
        })
        // var formElements = document.getElementsByClassName("formula")
        // for (var i = 0; i < formElements.length; i++) {
        //
        //     var formElement = formElements[i];
        //
        //     // Get the select element inside the current "formula" element
        //     var selectElement = formElement.getElementsByClassName("students_auto").item(0);
        //     // Get the value of the select element
        //     var selectValue = selectElement ? selectElement.value : null;
        //
        //     // Get the text input element inside the current "formula" element
        //     var textInputElement = formElement.getElementsByClassName("formula_input").item(0);
        //     // Get the value of the text input element
        //     var textInputValue = textInputElement ? textInputElement.value : null;
        //     if (selectValue === null || textInputValue === null){
        //         isValid = false
        //     }
        //     formulas['id'] = props.id.toString()
        //     formulas['students'] = selectValue
        //     formulas['formula'] = textInputValue
        //
        // }
        if(isValid){
            axios.post("set_eval_json",{'formulas':FormulaList,'task':props.task.id})
                .then(response=> {
                    if (response.data.error !== null && response.data.error !== undefined){
                        SetError(response.data.error)
                    }else{

                        props.task.evalfnjson = response.data
                        props.setopen(false);
                    }

                })
                .catch(error=> {
                    console.log(error);
                    SetError("An error occurred submitting the expressions")
                })
        }else{
            SetError("Error: you must set the students -- or default -- and the formula for all the formulas opened.")
        }

    }

    let handleAddFormula = (e) => {
        e.preventDefault()
        SetFormulas([...Formulas,<Formula task={props.task} id={Formulas.length.toString()} formula={FormulaList} setformula={SetFormulaList} />]);
    }

    useEffect(()=>{
        console.log('error',Error)
    },[Error])
    return (
        <div>



            <Dialog
                open={props.open}
                keepMounted
                disableBackdropClick
                maxWidth="lg"
                fullWidth
                sx = {{height:'100vh',overflowY:'scroll'}}
                onClose={()=>props.setopen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                {props.task && <DialogTitle><h2>{"Add formula to task: "}{props.task.name}</h2></DialogTitle>}
                <DialogContent>
                    <div>
                        <div style={{textAlign:'left',margin:'2%'}}>
                            {props.task.computational && props.task.assessment === false && <div>Here you can add one or more expressions used to compute the final scores. Each
                                expression combines the scores obtained in each homework. You can define multi+ple
                                expressions if some students
                                need ad-hoc score computations. If you assign to the same student multiple expression, the last defined will be applied.</div>}
                            {props.task.assessment && <div>    Here you can define how the calibration is performed. Associate for quartile a score. For example: 0.25: -0.30, 0.50: -0.10, 0.75: 0.00, 1.00: 0.10 where the quartile is the key and the score added or removed is the value. If you add multiple times an expression for "default" or for one or more students, the last defined will be applied</div>}

                            {Formulas}

                            {/*<div style={{textAlign:'left',margin:'2%'}}><Button onClick={handleAddFormula} variant={'outlined'}>New formula</Button></div>*/}
                        </div>




                    </div>
                </DialogContent>
                {Error && <div style={{padding:'2%'}}><b>{Error}</b></div>}

                <DialogActions>
                    <Button onClick={(e)=>{
                        e.preventDefault()
                        props.setopen(false)
                    }}>Cancel</Button>
                    <Button onClick={submitFormulas} >Ok</Button>
                </DialogActions>
            </Dialog>
        </div>

    );

}

export default ModalFormula;
