import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'


import {Redirect, useParams} from "react-router-dom";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper, Rating
} from "@mui/material";
import axios from "axios";



function GroupForm() {


    const { academicYears,email,taskinfo,info,groupinfo,courseslist,name,surname,degree,degrees,curCourse,group } = useContext(AppContext);
    const [TaskInfo,SetTaskInfo] = taskinfo
    const [Info,SetInfo] = info
    const [GroupInfo,SetGroupInfo] = groupinfo
    const [AcroInvalid,SetAcroInvalid] = useState(false)
    const [DescInvalid,SetDescInvalid] = useState(false)
    const [AcademicYears,SetAcademicYears] = academicYears
    const [Email,SetEmail] = email
    const [Name,SetName] = name
    const [Surname,SetSurname] = surname
    const [Degree,SetDegree] = degree
    const [ErrorEmail,SetErrorEmail] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [Acro,SetAcro] = useState("")
    const [Desc,SetDesc] = useState("")
    const [Group,SetGroup] = group
    const [Members,SetMembers] = useState(false)
    const [OriginalMembers,SetOriginalMembers] = useState(false)
    const { id } = useParams();
    const [OpenModal,SetOpenModal] = useState(false)
    const [MemberToDelete,SetMemberToDelete] = useState(false)
    const [Message,SetMessage] = useState(false)
    const [Error,SetError] = useState(false)
    const [Redir,SetRedir] = useState(false)
    const [MemberSearch,SetMemberSearch] = useState("")
    const [Modified,SetModified] = useState(false)
    const [Update,SetUpdate] = useState(false)
    const [DeletedMembers, SetDeletedMembers] = useState([])
    const [CurStudent,SetCurStudent] = useState(false)
    const [EvaluationOpen,SetEvaluationOpen] = useState(false)
    const [OpenDialogEval,SetOpenDialogEval] = useState(false)
    const [EvalEmail,SetEvalEmail] = useState(false)
    const [EvalStudent,SetEvalStudent] = useState(false)
    const [Score,SetScore] = useState(null)
    const [Comment,SetComment] = useState("")
    const [Loading,SetLoading] = useState(false)
    const [Evaluations,SetEvaluations] = useState(false)




    const styleObj = {


            color: 'darkgreen',     // Change the text color for disabled state
            border: '1px solid darkgreen',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state

    };
    const styleObj1 = {
        '&:disabled': {
            color: 'darkred',     // Change the text color for disabled state
            border: '1px solid darkred',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };

    useEffect(()=>{
        console.log('GROUP',Group)
    },[Group])



    useEffect(()=>{
        SetModified(false)
        var stud = Email +' - '+Name+' '+Surname+' - '+Degree
        SetCurStudent(stud)
        SetAcroInvalid(false)
        SetDescInvalid(false)
        axios.get('/group',{params:{courseid:id}}).then(response=>{
            if('name' in response.data['group']){
                SetGroup(response.data['group'])

            }
            else{
                var stud = Email +' - '+Name+' '+Surname+' - '+Degree
                SetMembers([stud])
            }
        })
        axios.get('/studentevaluation',{params:{courseid:id}}).then(response=>{
            SetEvaluations(response.data)
        })
        axios.get('/evaltaskopen',{params:{courseid:id}}).then(response=>{
            if(response.data['open']){
                SetEvaluationOpen(true)
            }
            else{
                SetEvaluationOpen(false)
            }
        })
    },[])

    useEffect(()=>{
        if(OpenDialogEval){
            SetLoading(true)
            axios.get('/studentevaluation',{params:{courseid:id}}).then(response=>{
                SetEvaluations(response.data)
                SetLoading(false)
            }).catch(error=>{
                console.log(error);
                SetLoading(false)
            })
        }

    },[OpenDialogEval])

    useEffect(()=>{
        if(Group && 'name' in Group){
            // SetMembers(Group.members.map(x=>x.email))
            SetAcro(Group.name)
            SetDesc(Group.description)
            var mem = []
            Group.members.map(m=>{
                mem.push(m.email+' - '+m.name+' '+m.surname+' - '+m.degree)
            })
            SetMembers(mem)
        }
        else{

            var stud = Email +' - '+Name+' '+Surname+' - '+Degree

            SetMembers([stud])
            SetGroup(false)
            SetAcro("")
            SetDesc("")
        }
    },[Group])

    useEffect(()=>{
        var mem = []
        var info_mem = []
        if (Group && Group.members){
            Group.members.map(m=>{
                var student = m.email+' - ' +m.name +' '+m.surname + ' - '+ m.degree
                mem.push(student)
            })
            SetMembers(mem)
        }

    },[Info,GroupInfo,TaskInfo,Group])



    function handleClose(){
        SetOpenModal(false)
        SetError(false)
    }

    function createGroup(e){
        e.preventDefault()

        if(AcroInvalid === false && DescInvalid === false){
            var action = "create"
            if(Group){
                action = Group.id
            }
            var memb = Members.map(x=>x.split(' - ')[0])
            axios.post("group/"+id,{acronym:Acro,description:Desc,members:memb,action:action})

                .then(function (response) {
                    //handle success
                    if(response.data['error']){
                        SetError(response.data['error'])
                        SetRedir(false)


                    }else{
                        if(response.data['group'] === {}){
                            SetGroup(false)
                        }else{
                            SetGroup(response.data['group'])
                        }
                        // SetError("Ok group updated")
                        // SetRedir(true)
                        SetError(false)
                        SetModified(true)
                        SetMessage(false)
                        SetOpenModal(false)
                        // SetTaskInfo(true)
                        // SetInfo(false)
                        // SetGroupInfo(false)

                    }
                })
                .catch(function (response) {
                    SetMessage(false)
                    SetError("An error occurred during group creation/update")
                });
        }


    }

    // useEffect(()=>{
    //     console.log('members',Members)
    //     if(Members && Email){
    //         console.log('members',Members.indexOf(Email))
    //
    //     }
    // },[Members])

    function handleCloseEval(){
        SetOpenDialogEval(false)
        SetScore(null)
        SetComment('')
        SetError(false)
    }

    useEffect(()=>{
        if(EvalEmail && Evaluations){
            var eval_student = Evaluations[EvalEmail]
            SetScore(eval_student['score'])
            SetComment(eval_student['comment'])
        }
    },[EvalEmail,Evaluations])


    function submitEvaluation(e){
        e.preventDefault();
        SetError(false)
        console.log('score',Score)
        console.log('comment',Comment)
        if(Score > 0 && (Score > 3 || (Score < 4 && Comment.trim().length > 10))){
            axios.post('/studentevaluation',{score:Score,comment:Comment,email:EvalEmail,courseid:id})
                .then(response=>{
                    SetOpenDialogEval(false)
                    SetScore(null)
                    SetComment('')
                    console.log('evaluation ok')
                })
                .catch(function (response) {
                    SetMessage(false)
                    SetScore(null)
                    SetComment('')
                    SetError("An error occurred during the evaluation")
                });

        }else{
            SetError('Set a score and a comment. If you assign a score lower than 4, you MUST add a comment. A comment must be at least 10 chars long.')
        }
    }
    return (
        <div>
            <Dialog
                open={OpenDialogEval}
                onClose={handleCloseEval}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Evaluate: {EvalStudent}
                </DialogTitle>
                <DialogContent>
                    {Loading === false ? <DialogContentText id="alert-dialog-description">
                        Please, provide a score from 1 (worst) to 5 (best) to assess the work of your team mates. If the score is lower than 4, providing a comment is mandatory; if the score is equal to 4 or 5, the comment is optional..
                        <hr/>
                        <div style={{textAlign: 'center'}}>
                            <Rating name="no-value" value={Score} onChange={(event, newValue) => {
                                SetScore(newValue);
                            }}/>
                        </div>
                        <div>
                            <TextField sx={{width: '100%'}}
                                       id="eval_comment"
                                       label="Comment"
                                       value={Comment}
                                       onChange={(event) => {
                                           SetComment(event.target.value);
                                       }}
                                       multiline
                                       maxRows={4}

                            />
                            {Error && <div style={{margin: '3%'}}><Alert severity="error">{Error}</Alert></div>}

                        </div>

                    </DialogContentText> : <div className={'circularbar'}><CircularProgress /></div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEval}>Cancel</Button>
                    <Button onClick={submitEvaluation} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={OpenModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Group information and members
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are updating the group information and its members.
                        <hr/>
                        <div>
                            <b>Acronym: </b> {Acro}
                        </div>
                        <div>
                            <b>Description: </b> {Desc}
                        </div>
                        <div>
                            <b>Members: </b> {Members && Members.map(x=><>{x.split(' - ')[0]}<br/> </>
                        )}
                        </div>
                        <hr/>
                        {Members && Email && Members.indexOf(CurStudent) === -1 && <b>If you accept you will not belong to this group anymore. This action is irreversible.</b>}<br/>
                        Continue?
                        {Error && <div style={{margin:'3%'}}><Alert severity="error">{Error}</Alert></div>}


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={createGroup} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>




            {CurCourse ? <div>



            {CurCourse.groupdeadline === false ? <Box   noValidate sx={{ mt: 1,margin:'2%' }}>
                {Group ? <h2>Your Group</h2> : <h2>New Group</h2>}
                <div style={{margin: '3%'}}>To {Group ? <>create</> : <>update</>} group you have to set: (i) an <b>acronym</b>,
                    a <b>description</b> and at least one member.
                    To add new members to your group, provide the mail of your colleague and search her clicking the
                    button <i>ADD MEMBER BY EMAIL</i>. If you have a group, and you want to remove one member, click on the DELETE button at the right of the related email. Then, click UPDATE.
                    If the member is found, it will be added to the members list on the right. If you want to remove
                    yourself from your current group, just go in the list
                    of members and click on the <i>DELETE</i> button at the right of your email, then click
                    on <i>UPDATE</i>.</div>
                    <Row>
                        <Col md={6}>
                            <div style={{marginTop:'3vh'}}>
                                {CurCourse.groupdeadline === false && <TextField sx={{width: '100%'}}
                                            error={AcroInvalid}
                                            helperText={AcroInvalid && "Acronym must NOT contain special chars, white spaces, and must be less than 10 and more than 3 chars long"}
                                            onChange={(e) => {
                                                SetAcro(e.target.value)
                                                const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\s]/;
                                                if (e.target.value.length > 10 || e.target.value.length < 3 || regex.test(e.target.value)) {
                                                    SetAcroInvalid(true)
                                                    SetModified(false)
                                                } else {
                                                    SetAcroInvalid(false)
                                                    SetModified(true)
                                                }
                                                SetMessage(false)
                                                SetError(false)
                                            }}
                                            size="small"
                                            value={Acro}
                                            name="acronym"
                                            required
                                            disabled={CurCourse.groupdeadline}
                                            id="standard-basic"
                                            label="Acronym"
                                            variant="outlined"/>
                                    // :
                                    // <div><b>Acronym</b><br/>
                                    // <h4>{Acro}</h4>
                                    // </div>
                                }
                            </div>
                            <div style={{marginTop:'3vh'}}>
                                {CurCourse.groupdeadline === false && <TextField
                                    required
                                    sx={{width: '100%'}}
                                    id="standard-desc-input"
                                    rows={7}
                                    disabled={CurCourse.groupdeadline}

                                    label="Description"
                                    onChange={(e) => {
                                        SetDesc(e.target.value)
                                        if (e.target.value.length > 400 || e.target.value.length < 10) {
                                            SetDescInvalid(true)
                                            SetModified(true)
                                        } else {
                                            SetDescInvalid(false)
                                            SetModified(true)

                                        }

                                        SetMessage(false)
                                        SetError(false)
                                    }}
                                    type="description"
                                    name="description"
                                    error={DescInvalid}
                                    multiline
                                    helperText={DescInvalid && "Description must be less than 400 , and more than 10 chars long"}
                                    value={Desc}
                                    size="small"
                                    variant="outlined"
                                />
                                    // :      <div><b>Description</b><br/>
                                    // <div>{Desc}</div>
                                // </div>
                                    }
                            </div>
                            <div style={{marginTop:'3vh'}}>

                                {CurCourse.groupdeadline === false && <TextField
                                    onChange={() => {
                                        SetMessage(false)
                                        SetError(false)
                                        SetErrorEmail(false)
                                        SetMemberSearch(document.getElementById('memberinput').value)
                                    }}
                                    sx={{width: '100%'}}
                                    error={ErrorEmail}
                                    helperText={ErrorEmail}
                                    id="memberinput"
                                    label="Add a New Member"
                                    type="member"
                                    name="member"
                                    disabled={CurCourse.groupdeadline}
                                    value={MemberSearch}
                                    size="small"
                                    variant="outlined"
                                />}
                            </div>
                            {CurCourse.groupdeadline === false && <Button  size={'small'}
                                            variant="contained" style={{marginTop:'1vh',width:'40%'}} onClick={(e)=>{
                                                e.preventDefault()
                                                axios.get('member',{params:{courseid:id,email:document.getElementById('memberinput').value}})
                                                    .then(response=> {
                                                        if (response.data['error']){
                                                            SetErrorEmail(response.data['error'])

                                                        }else{

                                                            SetMembers([...Members, response.data['student']])
                                                            SetMemberSearch("")
                                                            SetModified(true)
                                                        }
                                                    })
                                                    .catch(error=>{console.log(error)})
                        }} disabled={CurCourse.groupdeadline === true}>Search member</Button>}







                        </Col>
                        <Col md={1}></Col>
                        <Col md={5} style={{textAlign:'left'}}><h5>
                            Members
                        </h5>

                        <ol>

                            {Members.length > 0 && Members.map(member=><>
                                <div><span style={{display:'inline'}}>{member}</span><Button color = {member.startsWith(Email) ? "error" : "primary"}  sx={{display:'inline',marginLeft:'1%'}} size={'small'} onClick={(e)=>{
                                    e.preventDefault()
                                    SetDeletedMembers([...DeletedMembers,member])
                                    var members = Members.map(x=>x).filter(x=> x !== member)
                                    SetMembers(members)
                                    SetModified(true)



                                }} disabled={(!Group && Email === member )|| Group.groupdeadline === true}>Delete</Button></div>
                                </>)}

                        </ol>

                        </Col>


                    </Row>
                    <div style={{textAlign:'center'}}>
                        {CurCourse.groupdeadline === false && <Button type="submit"   size={'large'}
                                variant="contained" style={{marginTop:'5vh',width:'20vw'}} disabled={!Modified ||  AcroInvalid || DescInvalid || !Acro || !Desc || CurCourse.groupdeadline === true}
                        onClick={()=>SetOpenModal(true)}
                        >{Group ? "Update" : "Create"}</Button>}
                    </div>
                    {Error && <div style={{margin:'3%'}}><Alert severity="error">{Error}</Alert></div>}
                    {Message && <div style={{margin:'3%'}}><Alert severity="success">{Message}</Alert></div>}


            </Box> : <div>

                <h2>{Acro}</h2>
                <div>{Desc}</div>
                <hr/>
                <h5>Members</h5>

                    {Members.length > 0 && Members.map(member=><div style={{marginTop:'2%'}}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={6}>

                            <div style={{textAlign:'left'}}>{member.split('-')[1].trim()}</div>
                            </Col>
                            <Col md={4}>
                            <div style={{textAlign:'left'}}>
                                {!EvaluationOpen ?
                                <Button variant="outlined" color="error" sx={styleObj1} disabled size={'small'}>
                                    Evaluation closed
                                </Button>
                                : <Button variant="outlined"  disabled={member.split('-')[0].trim() === Email} sx={styleObj} size={'small'} onClick={()=> {
                                        SetEvalEmail(member.split('-')[0].trim())
                                        SetEvalStudent(member.split('-')[1].trim())
                                        SetOpenDialogEval(prev => !prev)
                                    }}>
                                    Evaluate
                                </Button>

                                }</div>
                            </Col>
                            <Col md={1}></Col>

                        </Row>
                    </div>)}


            </div>}




            </div> : <div className={'circularbar'}><CircularProgress /></div>}
        </div>
    );
}

export default GroupForm;
