import '../../App.css';
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'

// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect} from "react-router-dom";
import {Autocomplete, Collapse} from "@mui/material";


function SearchCourseBar(props) {


    const { academicYears,email,studentCourses } = useContext(AppContext);




    const filtered_list = props.filtered_list ? props.filtered_list : []






    return (
        <div>
            <Autocomplete
                id="grouped-demo"
                name={"searchbar"}
                options={props.entire_list.sort((a, b) => -b.name.toLowerCase()[0].localeCompare(a.name.toLowerCase()[0]))}
                groupBy={(option) => option.name.toLowerCase()[0]}
                getOptionLabel={(option) => {
                    if (option.motivation && option.motivation !== "") {
                        return option.name + '- [' + option.motivation + ']'
                    }else
                        {
                            return option.name
                        }
                    }}
                sx={{ width: '100%', margin:'3%' }}
                getOptionDisabled={(option) => {
                    if(filtered_list.map(x => x.id).indexOf(option.id) !== -1 ||  option.motivation === "Expired" || option.motivation === 'Enrolled'){
                        return true;
                    }
                    return false
                    }
                }


                onChange={(e,newVal)=>{
                    e.preventDefault()
                    console.log('course chosen', newVal)
                    if (newVal === null) {
                        newVal = false
                    }
                    if (props.selection.length > 0) {
                        props.selection[1](newVal)
                    }
                }}
                renderInput={(params) => <TextField {...params} label={props.label} />}
            />

        </div>
    );
}


export default SearchCourseBar;
