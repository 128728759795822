// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reglog{
    text-align:center;
    vertical-align:center;
    margin:2%;

}
img.login{
    height:5vh;
    margin:1vh;
}
img.orcid{
    height: 4vh;
    width: 4vh;
}
.footnote{
    font-size:0.65rem;
    text-align: left;
}
.not-satisfied{
    color: #941010;
}
.satisfied{
    color: #3c9410;
}`, "",{"version":3,"sources":["webpack://./src/components/login/login.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,SAAS;;AAEb;AACA;IACI,UAAU;IACV,UAAU;AACd;AACA;IACI,WAAW;IACX,UAAU;AACd;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB","sourcesContent":[".reglog{\r\n    text-align:center;\r\n    vertical-align:center;\r\n    margin:2%;\r\n\r\n}\r\nimg.login{\r\n    height:5vh;\r\n    margin:1vh;\r\n}\r\nimg.orcid{\r\n    height: 4vh;\r\n    width: 4vh;\r\n}\r\n.footnote{\r\n    font-size:0.65rem;\r\n    text-align: left;\r\n}\r\n.not-satisfied{\r\n    color: #941010;\r\n}\r\n.satisfied{\r\n    color: #3c9410;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
