import {useParams} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import {
    SearchState,
    FilteringState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    IntegratedSelection,
    DataTypeProvider,


} from '@devexpress/dx-react-grid';
import Button from "@mui/material/Button";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
var FileDownload = require('js-file-download');
import {
    Grid,
    Table,
    SearchPanel,
    TableHeaderRow,
    TableRowDetail,
    TableFilterRow,
    VirtualTable,
    DragDropProvider,
    Toolbar,
    PagingPanel,
    TableEditColumn,
    ColumnChooser,
    TableSelection,
    TableColumnVisibility,
    TableColumnResizing, ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
// import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faProjectDiagram, faPencilAlt, faComment} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import {AppContext} from "../../App";

import React, {useState, useEffect, useContext, createContext, useRef, useCallback} from "react";
import axios from "axios";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import TextField from "@mui/material/TextField";
import {GridExporter} from "@devexpress/dx-react-grid-export";

export default function GroupsList(props) {
    const {
        academicYears,
        curCourse,
        email,
        studentCourses,
        courseslist,
        studentDegree,
        degrees,
        tasks
    } = useContext(AppContext);

    const [StudentCourses, SetStudentCourses] = studentCourses
    const [AcademicYears, SetAcademicYears] = academicYears
    const [Error, SetError] = useState(false)
    const [Update, SetUpdate] = useState(false)
    const [Rows, SetRows] = useState([])
    const [OpenComment,SetOpenComment] = useState(false)
    const [Columns, SetColumns] = useState([])
    // const [Columns, SetColumns] = useState([{name: 'name', title: 'Name'},{name: 'description', title: 'Description'},{name: 'members', title: 'Members'}])
    const [CurTask, SetCurTask] = useState(false)
    // const [defaultColumnWidths, setdefaultColumnWidths] = useState([{columnName: 'name', width: 200},{columnName: 'description', width: 200},{columnName: 'members', width: 200}])
    const [defaultColumnWidths, setdefaultColumnWidths] = useState([])
    const {id} = useParams();


    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElTask, setAnchorElTask] = useState(null);
    const [OpenEval,SetOpenEval] = useState(false)
    const open = Boolean(anchorEl);
    const [deleteCols] = useState(['homework']);
    const [evalCols] = useState(['evaluate_homework']);
    const [evalupCols] = useState(['evaluation']);
    const [CurCourse,SetCurCourse] = curCourse
    const [Score,SetScore] = useState(null)
    const [Comment,SetComment] = useState(null)
    const [RowToEval,SetRowToEval] = useState(false)
    const [Evaluation,SetEvaluation] = useState(false)

    const [Attachment,SetAttachment] = useState(false)
    const [AttachmentName,SetAttachmentName] = useState(false)

    const [tableColumnVisibilityColumnExtensions] = useState([{
            columnName: 'homework',
            showInColumnChooser: false
        }, {columnName: 'evaluate_homework', hidden: true},{columnName: 'evaluation', hidden: true},{columnName: 'update', hidden: true},
    ]);

    const compareStrings = (a, b) => {
        const stringA = a.toLowerCase();
        const stringB = b.toLowerCase();
        if (stringA < stringB) {
            return -1;  // a should come before b
        }
        if (stringA > stringB) {
            return 1;   // a should come after b
        }
        return 0;       // a and b are equal
    };

    const [integratedSortingColumnExtensicons] = useState([
        { columnName: 'group', compare: compareStrings },{ columnName: 'email', compare: compareStrings },{ columnName: 'student', compare: compareStrings },
    ]);
    const [FilterExt] = useState([{
        columnName: 'homework',
        filteringEnabled: false,
        SortingEnabled: false
    }, {columnName: 'evaluate_homework', filteringEnabled: false, SortingEnabled: false}, {columnName: 'update', filteringEnabled: false, SortingEnabled: false}, {columnName: 'evaluation', filteringEnabled: false, SortingEnabled: false}])
    const [ResizeExt] = useState([{columnName: 'evaluate_homework', minWidth: 200, maxWidth: 200}])
    const [sortingStateColumnExtensions] = useState([
        {columnName: 'homework', sortingEnabled: false}, {columnName: 'evaluate_homework', sortingEnabled: false},{columnName: 'evaluation', sortingEnabled: false},{columnName: 'update', sortingEnabled: false},
    ]);
    const [pageSizes] = useState([5, 10, 25, 50, 0]);

    const onSave = (workbook) => {
        workbook.csv.writeBuffer().then((buffer) => {
            var filename = 'groups_'+CurCourse.acronym+'.csv'
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
        });
    };
    const exporterRef = useRef(null);

    const startExport = useCallback(() => {
        // console.log('export')
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    useEffect(() => {
        axios.get("courses",{params:{type:'professor',courseid:id}}).then(response=>{
            var res = response.data['courses'].filter(c => c.is_alias === false)
            SetCurCourse(res[0])

        }).catch(error=>console.log(error))
    }, [])

    useEffect(() => {
        if(CurCourse || Update){
            axios.get('get_groups', {params: {courseid: CurCourse.id}}).then(response => {
                SetRows(response.data['rows'])

                var cols = response.data['cols']
                // var wd_cols = cols.map(c=>({columnName:c.name, width: 200}))


                SetColumns(cols)
                // setdefaultColumnWidths(wd_cols)






                SetUpdate(false)
            })
        }

    }, [CurCourse,Update])


    useEffect(()=>{
        if(Columns.length > 0){
            var wd = Columns.map(c=>({'columnName':c.name,wordWrapEnabled: true,width:200}))
            setdefaultColumnWidths(wd)
        }
    },[Columns])





    function handleCloseSnack(){
        SetError(false)
    }

    
    const FilterCell = (props) => {
        const { column } = props;
        if(column.name === 'name' || column.name === 'description' || column.name === 'cur_state' || column.name === 'members' || column.name === 'members_email'){
            return <TableFilterRow.Cell {...props} />;

        }
        else
        {

            return <th className="MuiTableCell-root MuiTableCell-head" style={{borderBottom:'1px solid rgb(224, 224, 224)'}}> </th>
        }

    };


    const GroupCommentFormatter = ({row}) =>
        <div>

            <Button onClick={()=>SetOpenComment(row.comments)}><FontAwesomeIcon icon={faComment} color='#757575'/></Button>




        </div>
    const GroupCommentProvider = props => (
        <DataTypeProvider
            formatterComponent={GroupCommentFormatter}
            {...props}
        />
    );


    const handleClose = () => {
        SetOpenComment(false);
        SetEvaluation(false)
        SetRowToEval(false)
        SetAttachment(false)
        SetAttachmentName(false)
        SetScore(null)
        SetComment(null)
    };

    return(
        <div>
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <Dialog
                open={OpenComment}
                onClose={()=> {
                    SetOpenComment(false);
                }}
                fullWidth
                maxWidth="lg"

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    {OpenComment && OpenComment.map(comment=>
                        <div>
                            <div>task: {comment.task}</div>
                            <div style={{whiteSpace:'pre-line'}}>{comment.comment}</div><br/><hr/>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {
                        SetOpenComment(false);
                    }}>Close</Button>


                </DialogActions>
            </Dialog>

            <HeaderBar />
            {CurCourse && <div style={{padding:'1%'}}><a href={window.location.origin+'/courses/'+CurCourse.id}>Back to Courses</a></div>}
            {CurCourse ? <div>
                <div style={{textAlign:'center',margin:'2%'}}><h1>{CurCourse.name}</h1><h4>Groups</h4></div>
                {/*{OpenInfoModal && CurID && CurAnnotation && <InfoModal show={OpenInfoModal} setshow={SetOpenInfoModal} curid={CurID} annotation={CurAnnotation}  row={props.righe} />}*/}
                {/*{OpenContentModal && Content && <ContentModal show={OpenContentModal} setshow={SetOpenContentModal} content={Content} />}*/}
                {/*{OpenDeleteModal && IdToDel && <DeleteDocumentModal show={OpenDeleteModal} setshow={SetOpenDeleteModal} doc={IdToDel} setconfirm={SetConfirmDelete}/>}*/}
                {/*{OpenDownloadModal && IdToDownload && <DownloadModal show={OpenDownloadModal} setshow={SetOpenDownloadModal} doc={IdToDownload}/>}*/}
                <div style={{padding: '2%'}}>
                    {Rows && Columns.length > 0 && defaultColumnWidths.length === Columns.length &&
                        <>
                    <Grid
                        rows={Rows}
                        columns={Columns}
                    >


                        <SearchState/>
                        <PagingState
                            defaultCurrentPage={0}
                            defaultPageSize={25}
                        />
                        <GroupCommentProvider for={['comments']}/>

                        <FilteringState columnExtensions={FilterExt} defaultFilters={[]}/>
                        <IntegratedFiltering/>
                        <SortingState defaultSorting={[{columnName: 'name', direction: 'asc'},]}
                                      columnExtensions={sortingStateColumnExtensions}
                        />
                        <IntegratedSorting columnExtensions={integratedSortingColumnExtensicons} />
                        <IntegratedPaging/>
                        <Table  />
                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths}
                                             />
                        <TableHeaderRow showSortingControls/>

                        {/*<TableSelection showSelectAll />*/}
                        <TableFilterRow
                            cellComponent={FilterCell}
                        />
                        <PagingPanel
                            pageSizes={pageSizes}
                        />
                        <TableColumnVisibility
                            columnExtensions={tableColumnVisibilityColumnExtensions}
                        />


                        <Toolbar />

                        <ExportPanel startExport={startExport}/>

                    </Grid>
                            <GridExporter
                                ref={exporterRef}
                                rows={Rows}
                                columns={Columns}
                                onSave={onSave}
                            />

                </>}

            </div>

            </div> :
                <div className={'circularbar'}><CircularProgress /></div>}
        </div>

    );
}
